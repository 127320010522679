import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Form,
  Select,
  Upload,
  Row,
  Col,
  Skeleton,
  Alert,
  Descriptions,
  DescriptionsProps,
  Modal,
  Image,
  Carousel,
  Divider,
} from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";

import {
  useGetInspectorsQuery,
  useGetTaskByIdQuery,
  useEditTaskMutation,
} from "@services/api/index";
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileOutlined,
} from "@ant-design/icons";
import AppFormWrapper from "@components/appFormWrapper";
import MapWithPolygons from "@components/googleMapWithPolygon";
import moment from "moment";
import { Task } from "types";
import MapWithImages from "@components/googleMapWithImages";
import { API_BASE_URL } from "@constants/index";
import styles from "./viewTask.module.css";

const { Option } = Select;

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface Attachment {
  path?: string;
  lat: any;
  long: any;
  documentTyp?: string;
}

interface ImageMarker {
  coordinates: LatLngLiteral;
  images: string;
}

interface ViewTaskProps {
  onClose?: () => void;
  id: string;
  isLoaded: boolean;
}
interface Remarks {
  remarks: string;
  documentType: string;
  userName: string;
  createdAt: string;
}

interface ImagePreviewSectionProps {
  fileList: UploadFile[];
  handlePreview: (file: UploadFile) => void;
  previewOpen: boolean;
  handleCancel: () => void;
  previewImage: string;
  customItemRender: (
    originNode: React.ReactNode,
    file: UploadFile
  ) => React.ReactNode;
}

const ViewTask: React.FC<ViewTaskProps> = ({ onClose, id, isLoaded }) => {
  const [editTask, { isLoading, error }] = useEditTaskMutation();
  const [polygonData, setPolygonData] = useState<LatLngLiteral[] | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLngLiteral | null>(null);

  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [surveyImages, setSurveyImages] = useState<Attachment[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const carouselRef = useRef<any>(null);
  const [remarksArray, setRemarksArray] = useState<Remarks[]>([]);

  const [showcauseReplyFileList, setShowcauseReplyFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [showcauseFileList, setShowcauseFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [noticeReplyFileList, setNoticeReplyFileList] = useState<
    ExtendedUploadFile[]
  >([]);

  const [documentFileList, setDocumentFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [noticeFileList, setNoticeFileList] = useState<ExtendedUploadFile[]>(
    []
  );
  const [propertyFileList, setPropertyFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [challanFileList, setChallanFileList] = useState<ExtendedUploadFile[]>(
    []
  );
  const [sealFileList, setSealFileList] = useState<ExtendedUploadFile[]>([]);
  const [closeFileList, setCloseFileList] = useState<ExtendedUploadFile[]>([]);
  const [disposalFileList, setDisposalFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [demolationFileList, setDemolationFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [desealFileList, setDesealFileList] = useState<ExtendedUploadFile[]>(
    []
  );
  const [descalingFileList, setDescalingFileList] = useState<
    ExtendedUploadFile[]
  >([]);

  interface ExtendedUploadFile extends UploadFile {
    base64?: string;
  }

  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

  const imageSections = [
    { type: "Notice", title: "Notice", fileList: noticeFileList },
    {
      type: "NoticeReply",
      title: "Notice Reply",
      fileList: noticeReplyFileList,
    },
    {
      type: "ShowCause",
      title: "Show Cause",
      fileList: showcauseFileList,
    },
    {
      type: "ShowCauseReply",
      title: "Show Cause Reply",
      fileList: showcauseReplyFileList,
    },
    { type: "Challan/Fee", title: "Challan", fileList: challanFileList },
    { type: "Seal", title: "Seal", fileList: sealFileList },
    { type: "Closed", title: "Close", fileList: closeFileList },
    {
      type: "DescalingFee",
      title: "Descaling Fee",
      fileList: descalingFileList,
    },
    { type: "De-seal", title: "De-seal", fileList: desealFileList },
    { type: "Demolition", title: "Demolation", fileList: demolationFileList },
    {
      type: "DisposeOff",
      title: "Disposal Images",
      fileList: disposalFileList,
    },
    {
      type: "PropertyDocuments",
      title: "Document Images",
      fileList: documentFileList,
    },
    {
      type: "Attachments",
      title: "Property Images",
      fileList: propertyFileList,
    },
  ];

  const handleCancel = () => setPreviewOpen(false);
  const [previewImage, setPreviewImage] = useState("");
  // const handlePreview = (imageUrl: string) => {
  //   setPreviewImage(imageUrl);
  //   setPreviewOpen(true);
  // };

  // const handlePreview = (index: number) => {
  //   setCurrentSlideIndex(index); // Set the index of the clicked image
  //   setPreviewOpen(true); // Open the modal
  // };

  useEffect(() => {
    if (previewOpen && carouselRef.current) {
      // Jump to the current slide and force autoplay
      carouselRef.current.goTo(currentSlideIndex, true); // true forces the transition
    }
  }, [previewOpen, currentSlideIndex]);

  const {
    data: inspectors,
    error: inspectorsError,
    isLoading: inspectorsIsLoading,
  } = useGetInspectorsQuery();

  const {
    data: taskDataById,
    error: taskDataError,
    isLoading: taskDataIsLoading,
  } = useGetTaskByIdQuery(id);

  const task = useMemo<Task | undefined>(
    () => taskDataById && taskDataById.data,
    [taskDataById]
  );

  useEffect(() => {
    if (taskDataById) {
      const task: Task = taskDataById.data;

      // form.setFieldsValue({
      //   assignedToId: task.assignedToId,
      //   dueDate: moment(task.dueDate),
      //   propertyNo: task.propertyNo,
      //   propertyName: task.propertyName,
      //   streetName: task.streetName,
      //   ownerName: task.ownerName,
      //   phoneNo: task.phoneNo,
      //   plotLength: task.plotLength,
      //   plotWidth: task.plotWidth,
      //   buildingHeight: task.buildingHeight,
      //   floorAreaSft: task.floorAreaSft,
      //   floorAreaSqy: task.floorAreaSqy,
      //   noOfStories: task.noOfStories,
      //   landUseConversionAndRegularizationCharges:
      //     task.landUseConversionAndRegularizationCharges,
      //   inspectorRemarks: task.inspectorRemarks,
      //   tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges:
      //     task.tentativeAmountOnAccountOfConstructionWithoutApprovalAreaCharges,
      // });

      if (task.buildingCoordinates) {
        setPolygonData(task.buildingCoordinates);
      }
      if (task.attachments) {
        setSurveyImages(task.attachments || []);
      }

      if (task.lat && task.long) {
        setMapCenter({
          lat: parseFloat(task.lat),
          lng: parseFloat(task.long),
        });
      }

      if (task && task.remarks) {
        setRemarksArray((task as any).remarks || []);
      }
      if (task && task.attachments) {
        const initialPropertyFileList: any[] = [];
        const initialDocumentFileList: any[] = [];
        const initialNoticeFileList: any[] = [];
        const initialNoticeReplyFileList: any[] = [];
        const initialShowCauseFileList: any[] = [];
        const initialShowCauseReplyFileList: any[] = [];
        const initialCloseFileList: any[] = [];
        const initialSealFileList: any[] = [];
        const initialChallanFileList: any[] = [];
        const initialDemolationFileList: any[] = [];
        const initialDesealFileList: any[] = [];
        const initialDescalingFileList: any[] = [];
        const initialDisposalFileList: any[] = [];

        task.attachments.forEach((attachment: any, index: number) => {
          const commonData = {
            uid: `-1-${index}`,
            name: attachment.name || `Attachment-${index + 1}`,
            url: attachment.path ? `${API_BASE_URL}${attachment.path}` : "",
          };

          switch (attachment?.documentTyp) {
            case "Attachments":
              initialPropertyFileList.push(commonData);
              break;
            case "PropertyDocuments":
              initialDocumentFileList.push(commonData);
              break;
            case "Notice":
              initialNoticeFileList.push(commonData);
              break;
            case "NoticeReply":
              initialNoticeReplyFileList.push(commonData);
              break;
            case "ShowCause":
              initialShowCauseFileList.push(commonData);
              break;
            case "ShowCauseReply":
              initialShowCauseReplyFileList.push(commonData);
              break;
            case "Challan/Fee":
              initialChallanFileList.push(commonData);
              break;
            case "DisposeOff":
              initialDisposalFileList.push(commonData);
              break;
            case "Closed":
              initialCloseFileList.push(commonData);
              break;
            case "Seal":
              initialSealFileList.push(commonData);
              break;
            case "Demolation":
              initialDemolationFileList.push(commonData);
              break;
            case "De-seal":
              initialDesealFileList.push(commonData);
              break;
            case "DescalingFee":
              initialDescalingFileList.push(commonData);
              break;
            default:
              break;
          }
        });

        setPropertyFileList(initialPropertyFileList);
        setDocumentFileList(initialDocumentFileList);
        setNoticeFileList(initialNoticeFileList);
        setNoticeReplyFileList(initialNoticeReplyFileList);
        setShowcauseFileList(initialShowCauseFileList);
        setShowcauseReplyFileList(initialShowCauseReplyFileList);
        setChallanFileList(initialChallanFileList);
        setSealFileList(initialSealFileList);
        setCloseFileList(initialCloseFileList);
        setDemolationFileList(initialDemolationFileList);
        setDesealFileList(initialDesealFileList);
        setDescalingFileList(initialDescalingFileList);
        setDisposalFileList(initialDisposalFileList);
      }
    }
  }, [taskDataById, form]);

  // const handlePolygonChange = (newPolygons: LatLngLiteral[] | null) => {
  //   setPolygonData(newPolygons ? newPolygons : polygonData);
  // };

  // const handleUploadChange = (info: any) => {
  //   setFileList(info.fileList);
  // };

  const convertFileToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  // const center = polygonData && polygonData.length > 0 ? polygonData[0] : {
  //   lat: 33.6938,
  //   lng: 73.0652,
  // };

  const center = {
    lat: 33.6938,
    lng: 73.0652,
  };

  function convertCamelCaseToLabel(text: string): string {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, " $1$2")
      .replace(/^./, (str) => str.toUpperCase());
  }

  const items: DescriptionsProps["items"] = useMemo(() => {
    if (!taskDataById) return [];

    const task = taskDataById.data as Task;

    return Object.keys(task)
      .filter(
        (key) =>
          key.slice(-2) !== "Id" &&
          key !== "attachments" &&
          key !== "violationIds" &&
          key !== "id" &&
          key !== "propertyTypeId" &&
          key !== "assignedToId" &&
          key !== "hierarchy" &&
          key !== "remarks" &&
          key !== "dueDate" &&
          key !== "actualUsageOther" &&
          key !== "currenUsageOther" &&
          task[key as keyof Task] !== null
      )
      .map((key, index) => {
        let value = task[key as keyof Task];

        if (typeof value === "string" || value instanceof Date) {
          if (moment(value, moment.ISO_8601, true).isValid()) {
            value = moment(value).format("DD-MM-YYYY");
          }
        }

        const displayValue =
          typeof value === "object" && value !== null
            ? JSON.stringify(value)
            : value?.toString();

        return {
          key: index + 1,
          label: convertCamelCaseToLabel(key),
          children: key === "id" ? index + 1 : displayValue,
        };
      });
  }, [taskDataById]);

  const imageMarkers: ImageMarker[] = surveyImages
    .filter((attachment) => attachment.documentTyp === "Attachments")
    .map((attachment) => ({
      coordinates: {
        lat: parseFloat(attachment.lat),
        lng: parseFloat(attachment.long),
      },
      images: attachment.path ? `${API_BASE_URL}${attachment.path}` : "", // Wrapping the path in an array
    }));

  const handlePreview = async (file: ExtendedUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await convertFileToBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const getFileIcon = (file: any) => {
    const isImage = /\.(png|jpe?g|gif)$/i.test(file.url || file.name);
    const isDocument = /\.(pdf|docx?|xlsx?)$/i.test(file.url || file.name);

    if (isImage) {
      return (
        <EyeOutlined
          style={{ fontSize: "16px", color: "#1890ff" }}
          onClick={() => handlePreview(file)}
        />
      );
    } else if (isDocument) {
      return (
        <DownloadOutlined
          style={{ fontSize: "16px", color: "#1890ff" }}
          onClick={() => window.open(file.url, "_blank")}
        />
      );
    } else {
      return <FileOutlined style={{ fontSize: "32px", color: "#1890ff" }} />;
    }
  };

  const customItemRender = (originNode: any, file: any) => {
    const isNewUpload = /\.(png|jpe?g|gif|pdf|docx?|xlsx?)$/i.test(file.name);

    return (
      <div>
        <div
          style={{
            width: "100px",
            height: "90px",
          }}
        >
          {originNode}
        </div>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            marginTop: "8px",
            paddingTop: "8px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {getFileIcon(file)}
          {isNewUpload && (
            <DeleteOutlined
              style={{ fontSize: "16px", color: "red" }}
              onClick={() => handleRemove(file)}
            />
          )}
        </div>
      </div>
    );
  };

  const ImagePreviewSection: React.FC<ImagePreviewSectionProps> = ({
    fileList,
    handlePreview,
    previewOpen,
    handleCancel,
    previewImage,
    customItemRender,
  }) => (
    <>
      {fileList.length >= 1 && (
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              beforeUpload={() => false}
              showUploadList={{
                showPreviewIcon: false,
                showDownloadIcon: false,
                showRemoveIcon: false,
              }}
              itemRender={(originNode, file) =>
                customItemRender(originNode, file)
              }
            />
            <Modal
              open={previewOpen}
              onCancel={handleCancel}
              bodyStyle={{ padding: "20px", backgroundColor: "white" }}
              footer={null}
              closeIcon={
                <CloseOutlined
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    borderRadius: "10%",
                    padding: "5px",
                  }}
                />
              }
            >
              <img
                alt="attachment"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </Row>
        </Col>
      )}
    </>
  );

  return (
    <div className="h-full pb-20 gap-2 flex">
      <div className="h-full w-[60%]">
        {/* <MapWithPolygons initialCenter={center} initialPolygons={polygonData} onPolygonChange={handlePolygonChange} isLoaded={isLoaded} /> */}
        <MapWithImages
          center={mapCenter || center}
          imageMarkers={imageMarkers}
          isLoaded={true}
        />

        <Col span={24}>
          <Divider />
        </Col>
        <div className="h-full">
          {imageSections.map((section, index) => {
            const remarks = remarksArray.filter(
              (remarks) => remarks.documentType == section.type
            );

            return section.fileList && section.fileList.length > 0 ? (
              <React.Fragment key={index}>
                <div>
                  {<h2 style={{ fontWeight: "bold" }}>{section.title}</h2>}
                  {remarks.length > 0 && (
                    <h3>Uploaded By: {remarks[0].userName}</h3>
                  )}
                  {remarks.length > 0 && <h3>Remarks: {remarks[0].remarks}</h3>}
                  {remarks.length > 0 && (
                    <h3>
                      Upload Date:{" "}
                      {remarks[0]?.createdAt
                        ? moment(remarks[0].createdAt).format("DD-MM-YYYY")
                        : "No Date Available"}
                    </h3>
                  )}
                </div>

                <ImagePreviewSection
                  fileList={section.fileList}
                  handlePreview={handlePreview}
                  previewOpen={previewOpen}
                  handleCancel={handleCancel}
                  previewImage={previewImage}
                  customItemRender={customItemRender}
                />
                <Col span={24}>
                  <Divider />
                </Col>
              </React.Fragment>
            ) : null;
          })}
        </div>

        {/* <Col span={24}>
          <Divider />
        </Col> */}
      </div>
      <div className="h-[125vh] w-[40%] overflow-y-auto">
        <AppFormWrapper
          onClose={onClose}
          error={taskDataError}
          isLoading={taskDataIsLoading}
        >
          <>
            {taskDataIsLoading ? (
              <Skeleton active />
            ) : taskDataError ? (
              <Alert
                message={
                  (taskDataError as any)?.data?.message || "An error occurred"
                }
                type="error"
                className="mb-4"
              />
            ) : (
              <>{task && <Descriptions bordered column={1} items={items} />}</>
            )}
          </>
        </AppFormWrapper>
      </div>
    </div>
  );
};

export default ViewTask;
