import AppPageWrapper from '@components/appPageWrapper';
import React from 'react';
import usePermissions from '@hooks/usePermissions';
import { permissions } from 'permissions';
import DashboardScreen from '@components/dashboard';
import UserProfile from '@components/userProfile';

const Dashboard: React.FC = () => {
    const { hasPermission } = usePermissions();

    return (hasPermission(permissions.VIEW_DASHBOARD) ?
        <AppPageWrapper
            moduleName='Dashboard'
            pagetTitle='Dashboard'
            listComp={<DashboardScreen />}
            userProfile={(id: string, isLoaded: boolean) => <UserProfile id={id} isLoaded={isLoaded}/>}
        />
        : null
    );
};

export default Dashboard;
