import React, { useState } from "react";
import { Form, Input, notification, Select } from "antd";
import {
  useCreateAreaMutation,
  useGetPermissionsQuery,
  useGetSubZoneDropdownQuery,
  useGetZoneDropdownQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { areaValidator } from "@validators/areaValidator";
import NotificationHandler from "@components/notificationHandler";

const { Option } = Select;

interface AddAreaProps {
  onClose?: () => void;
}

const AddArea: React.FC<AddAreaProps> = ({ onClose }) => {
  const [form] = Form.useForm();
  const { openNotificationWithIcon } = NotificationHandler();
  const [createArea, { isLoading, error }] = useCreateAreaMutation();
  const [selectedZoneId, setSelectedZoneId] = useState<string | null>("");

  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  const {
    data: zone,
    error: zoneError,
    isLoading: zoneIsLoading,
  } = useGetZoneDropdownQuery();
  const {
    data: subZone,
    error: subZoneError,
    isLoading: subZoneIsLoading,
    refetch: refetchSubZone,
  } = useGetSubZoneDropdownQuery({ zoneId: selectedZoneId as string });

  const onFinish = async (values: any) => {
    try {
      let permissionIds: string[] = [];

      for (const value of Object.values(values)) {
        if (value && Array.isArray(value)) {
          permissionIds = permissionIds.concat(value);
        }
      }
      const payload = {
        name: values.name,
        subZoneId: values.subZoneId,
      };
      const result = await createArea(payload).unwrap();
      openNotificationWithIcon(
        "success",
        result?.message || "Area Created Successfully!"
      );
      onClose && onClose();
    } catch (err: any) {
      // openNotificationWithIcon("error", err?.data?.message);
    }
  };

  const handleZoneChange = (newZoneId: string | null) => {
    setSelectedZoneId(newZoneId);
    if (newZoneId) {
      refetchSubZone();
    }
    form.resetFields(["subZoneId"]);
  };

  const onFinishFailed = (errorInfo: any) => {};
  return (
    <AppFormWrapper
      form={form}
      error={error || permissionsError}
      initialValues={{ name: null, permissions: null }}
      isLoading={isLoading || permissionsIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Add"
    >
      <Form.Item label="Area Name" name="name" rules={areaValidator.name}>
        <Input
          maxLength={30}
          className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          placeholder="Enter area name"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item label="Zone" name="zoneId" rules={areaValidator.subZone}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select zone"
          loading={zoneIsLoading}
          disabled={zoneIsLoading || isLoading}
          className="rounded-md"
          onChange={handleZoneChange}
        >
          {zone?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Sub Zone"
        name="subZoneId"
        rules={areaValidator.subZone}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select sub zone"
          loading={subZoneIsLoading}
          disabled={subZoneIsLoading || isLoading}
          className="rounded-md"
        >
          {subZone?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default AddArea;
