import React, { useEffect } from "react";
import { Form, Input, Select, Skeleton } from "antd";
import {
  useGetAreaByIdQuery,
  useGetSubZoneDropdownQuery,
  useGetZoneDropdownQuery,
} from "@services/api/index";
import AppFormWrapper from "@components/appFormWrapper";

const { Option } = Select;

interface ViewAreaProps {
  onClose?: () => void;
  id: string;
}

const ViewArea: React.FC<ViewAreaProps> = ({ onClose, id }) => {
  const { data: areaById } = useGetAreaByIdQuery(id);
  const [form] = Form.useForm();

  const {
    data: zone,
    error: zoneError,
    isLoading: zoneIsLoading,
  } = useGetZoneDropdownQuery();

  const {
    data: subZoneList,
    error: subZoneError,
    isLoading: subZoneIsLoading,
  } = useGetSubZoneDropdownQuery({});

  useEffect(() => {
    if (areaById) {
      form.setFieldsValue({
        name: areaById?.data.name,
        zoneId: areaById?.data.zoneId,
        subZoneByIdzone: areaById?.data.subZoneId,
      });
    }

    return () => form.resetFields();
  }, [areaById, form]);
  return (
    <>
      {subZoneIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <AppFormWrapper
            onClose={onClose}
            isLoading={subZoneIsLoading}
            error={subZoneError}
          >
            <h1>Name</h1>
            <Input
              value={areaById?.data?.name}
              disabled={true}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            <h1>Zone</h1>
            <Select
              showSearch
              optionFilterProp="children"
              value={areaById?.data?.zoneId}
              placeholder="Select zone "
              loading={zoneIsLoading}
              disabled={true}
              className="rounded-md width-100"
            >
              {zone?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
            <h1>Sub Zone</h1>
            <Select
              showSearch
              optionFilterProp="children"
              value={areaById?.data?.subZoneId}
              placeholder="Select sub zone "
              loading={subZoneIsLoading}
              disabled={true}
              className="rounded-md width-100"
            >
              {subZoneList?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </AppFormWrapper>
        </>
      )}
    </>
  );
};

export default ViewArea;
