import React, { useState, ReactNode, useEffect } from "react";
import {
  useChangeStatusPropertyMutation,
  useDeletePropertyMutation,
  useGetPropertyQuery,
} from "@services/api/index";
import styles from "./propertyList.module.css";
import List from "@components/list";
import { DEFAULT_PAGE, PAGE_LIMIT } from "@constants/index";
import { Switch, Modal, Alert, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";

interface Property {
  id: string;
  name: string;
  status: string;
}

interface PropertyTypeListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
}

const PropertyTypeList: React.FC<PropertyTypeListProps> = ({
  editComp,
  viewComp,
}) => {
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const { data: property, error, isLoading } = useGetPropertyQuery(pagination);

  const [
    deleteProperty,
    { isLoading: deletePropertyIsloading, error: deletePropertyError },
  ] = useDeletePropertyMutation();
  const [
    changeStatusPropertyType,
    {
      isLoading: changeStatusPropertyIsloading,
      error: changeStatusPropertyError,
    },
  ] = useChangeStatusPropertyMutation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<Property | null>(null);

  const handleToggleChange = async (checked: boolean, record: Property) => {
    const result = await changeStatusPropertyType(record.id).unwrap();
  };

  const showDeleteConfirm = (record: Property) => {
    setRecordToDelete(record);
    setIsDeleteModalOpen(true); // Open the delete confirmation modal
  };

  const handleDelete = async () => {
    if (recordToDelete) {
      const result = await deleteProperty(recordToDelete.id).unwrap();
      notification.success({
        message: "Property Type Deleted successfully:",
        placement: "topRight",
        duration: 3, // Duration in seconds
      });
      // Implement actual delete functionality here
    }
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  const handleCancel = () => {
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  useEffect(() => {
    if (changeStatusPropertyError) {
      notification.error({
        message: "Property Type Updated failed:",
        placement: "topRight",
        duration: 3, // Duration in seconds
      });
    }
  }, [changeStatusPropertyError]);

  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: styles.tableCell,
    },
    {
      title: "Usages",
      dataIndex: "usages",
      key: "usages",
      className: styles.tableCell,
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   render: (text: any, record: Property) => (
    //     <Switch
    //       disabled={changeStatusPropertyIsloading}
    //       checked={record.status == "active"}
    //       onChange={(checked) => handleToggleChange(checked, record)}
    //     />
    //   ),
    //   className: styles.tableCell,
    // },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: Property) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_PROPERTY_TYPE) &&
            viewComp &&
            viewComp(record.id)}
          {/* {hasPermission(permissions.EDIT_PROPERTY_MANAGEMENT) &&
            editComp &&
            editComp(record.id)} */}
          {/* {hasPermission(permissions.DELETE_PROPERTY_MANAGEMENT) && (
            <DeleteOutlined
              className={styles.icon}
              onClick={() => showDeleteConfirm(record)}
            /> 
          )}*/}
        </div>
      ),
      className: styles.tableCell,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={property}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />
      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={handleCancel}
        loading={deletePropertyIsloading}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this record?</p>
        {error && (
          <Alert
            message={(deletePropertyError as any)?.data?.message}
            type="error"
            className="mb-4"
          />
        )}
      </Modal>
    </div>
  );
};

export default PropertyTypeList;
