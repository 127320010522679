import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'types';

interface AuthState {
    token: string | null;
    refreshToken: string | null;
    user: any; // You can define a specific user type instead of 'any'
}

const initialState: AuthState = {
    token: null,
    user: null,
    refreshToken: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<{ token: any; user?: any, refreshToken: any }>) => {
            if(action.payload.token)
            state.token = action.payload.token;
            state.user = action.payload.user || state.user;
            action.payload.token && localStorage.setItem('token', action.payload.token);
            action.payload.refreshToken && localStorage.setItem('refresh-token', action.payload.refreshToken);
            action.payload.user && localStorage.setItem('user', JSON.stringify(action.payload.user));
        },
        clearCredentials: (state) => {
            state.token = null;
            state.user = null;
            state.refreshToken = null;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('refresh-token')
        },
    },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;