import React, { ReactNode, useState } from "react";
import {
  useDeleteSocietyMutation,
  useGetSocietyQuery,
} from "@services/api/index";
import styles from "./society.module.css";
import List from "@components/list";
import { DEFAULT_PAGE, PAGE_LIMIT } from "@constants/index";
import { Switch, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { permissions } from "permissions";
import usePermissions from "@hooks/usePermissions";
import { Society } from "types";
import { Tooltip } from "antd";

interface SocietyManagement {
  id: string;
  name: string;
  status: string;
}

interface SocietyManagementListProps {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
  searchValue?: string;
}

const SocietyManagementList: React.FC<SocietyManagementListProps> = ({
  editComp,
  viewComp,
  searchValue,
}) => {
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    limit: PAGE_LIMIT,
  });
  const {
    data: society,
    error,
    isLoading,
  } = useGetSocietyQuery({ ...pagination, searchText: searchValue });
  const [
    deleteSociety,
    { isLoading: deleteSocietyIsloading, error: deleteSocietyError },
  ] = useDeleteSocietyMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<Society | null>(null);

  const showDeleteConfirm = (record: Society) => {
    setRecordToDelete(record);
    setIsDeleteModalOpen(true); // Open the delete confirmation modal
  };

  const handleDelete = async () => {
    if (recordToDelete?.id) {
      const result = await deleteSociety(recordToDelete?.id).unwrap();
      notification.success({
        message: "Society Deleted successful:",
        placement: "topRight",
        duration: 3, // Duration in seconds
      });
    }
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  const handleCancel = () => {
    setIsDeleteModalOpen(false);
    setRecordToDelete(null);
  };

  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Society Name",
      dataIndex: "name",
      key: "name",
      className: styles.tableCell,
    },

    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: SocietyManagement) => (
        <div className="flex gap-4">
          {hasPermission(permissions.VIEW_SOCIETY) &&
            viewComp &&
            viewComp(record.id)}
          {hasPermission(permissions.EDIT_SOCIETY) &&
            editComp &&
            editComp(record.id)}
          {/* {hasPermission(permissions.DELETE_SOCIETY) && (
            <Tooltip title="Delete Society">
              <DeleteOutlined
                className={styles.icon}
                onClick={() => showDeleteConfirm(record)}
              />{" "}
            </Tooltip>
          )} */}
        </div>
      ),
      className: styles.tableCell,
    },
  ];

  return (
    <div className="bg-[#f5f5f5]">
      <List
        data={society}
        columns={columns}
        setPagination={setPagination}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        open={isDeleteModalOpen}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
    </div>
  );
};

export default SocietyManagementList;
