import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  notification,
  Row,
  Col,
  DatePicker,
  Divider,
  Upload,
  Button,
  Card,
  Modal,
  Table,
} from "antd";
import {
  DownloadOutlined,
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  FileOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import type { GetProp, UploadFile, UploadProps } from "antd";
import type { RcFile } from "antd/es/upload/interface";

import {
  // useGetInspectorsQuery,
  useGetTaskByIdQuery,
  useEditTaskMutation,
  useGetViolationDropdownQuery,
  useGetAreaDropdownQuery,
  useGetSubAreaDropdownQuery,
  useGetPropertyTypeUsageDropdownQuery,
} from "@services/api/index";
import AppFormWrapper from "@components/appFormWrapper";
import moment from "moment";
import { Task } from "types";
import MapWithImages from "@components/googleMapWithImages";
import { taskValidator } from "@validators/taskValidator";
import { API_BASE_URL } from "@constants/index";
import "./editTask.module.css";
import TextArea from "antd/es/input/TextArea";
import { values } from "lodash";

interface ImageMarker {
  coordinates: LatLngLiteral;
  images: string;
}

interface Attachment {
  path?: string;
  lat: any;
  long: any;
}

const { Option } = Select;

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface Attachment {
  file: string;
  lat: any;
  long: any;
  documentTyp?: string;
}

interface EditTaskProps {
  onClose?: () => void;
  id: string;
  isLoaded: boolean;
}

interface Remarks {
  remarks: string;
  documentType: string;
  userName: string;
  createdAt: string;
}

interface ImagePreviewSectionProps {
  fileList: UploadFile[];
  handlePreview: (file: UploadFile) => void;
  previewOpen: boolean;
  handleCancel: () => void;
  previewImage: string;
  customItemRender: (
    originNode: React.ReactNode,
    file: UploadFile
  ) => React.ReactNode;
}

const EditTask: React.FC<EditTaskProps> = ({ onClose, id, isLoaded }) => {
  const [editTask, { isLoading, error }] = useEditTaskMutation();

  const [form] = Form.useForm();
  const [surveyImages, setSurveyImages] = useState<Attachment[]>([]);
  const [polygonData, setPolygonData] = useState<LatLngLiteral[] | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLngLiteral | null>(null);
  const [allottedUse, setAllottedUse] = useState<string | undefined>();
  const [currentUse, setCurrentUse] = useState<string | undefined>();
  const [propertyType, setPropertyType] = useState<string | undefined>();
  const [remarksArray, setRemarksArray] = useState<Remarks[]>([]);
  const [challanFileList, setChallanFileList] = useState<ExtendedUploadFile[]>(
    []
  );
  const [sealFileList, setSealFileList] = useState<ExtendedUploadFile[]>([]);
  const [closeFileList, setCloseFileList] = useState<ExtendedUploadFile[]>([]);

  const [selectedViolation, setSelectedViolation] = useState<
    string[] | undefined
  >([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [fileList, setFileList] = useState<ExtendedUploadFile[]>([]);
  const [showcauseReplyFileList, setShowcauseReplyFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [showcauseFileList, setShowcauseFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [noticeReplyFileList, setNoticeReplyFileList] = useState<
    ExtendedUploadFile[]
  >([]);

  const [documentFileList, setDocumentFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [noticeFileList, setNoticeFileList] = useState<ExtendedUploadFile[]>(
    []
  );
  const [propertyFileList, setPropertyFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [disposalFileList, setDisposalFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [demolationFileList, setDemolationFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [desealFileList, setDesealFileList] = useState<ExtendedUploadFile[]>(
    []
  );
  const [descalingFileList, setDescalingFileList] = useState<
    ExtendedUploadFile[]
  >([]);
  const [uploadError, setUploadError] = useState("");

  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

  interface ExtendedUploadFile extends UploadFile {
    base64?: string;
  }

  const imageSections = [
    { type: "Notice", title: "Notice", fileList: noticeFileList },
    {
      type: "NoticeReply",
      title: "Notice Reply",
      fileList: noticeReplyFileList,
    },
    {
      type: "ShowCause",
      title: "Show Cause",
      fileList: showcauseFileList,
    },
    {
      type: "ShowCauseReply",
      title: "Show Cause Reply",
      fileList: showcauseReplyFileList,
    },
    { type: "Challan/Fee", title: "Challan", fileList: challanFileList },
    { type: "Seal", title: "Seal", fileList: sealFileList },
    { type: "Closed", title: "Close", fileList: closeFileList },
    {
      type: "DescalingFee",
      title: "Descaling Fee",
      fileList: descalingFileList,
    },
    { type: "De-seal", title: "De-seal", fileList: desealFileList },
    { type: "Demolition", title: "Demolation", fileList: demolationFileList },
    {
      type: "DisposeOff",
      title: "Disposal Images",
      fileList: disposalFileList,
    },
    {
      type: "PropertyDocuments",
      title: "Document Images",
      fileList: documentFileList,
    },
    {
      type: "Attachments",
      title: "Property Images",
      fileList: propertyFileList,
    },
  ];

  const {
    data: violations,
    error: violationsError,
    isLoading: violationsIsLoading,
  } = useGetViolationDropdownQuery();

  const {
    data: taskDataById,
    error: taskDataError,
    isLoading: taskDataIsLoading,
  } = useGetTaskByIdQuery(id);

  const {
    data: areas,
    error: areasError,
    isLoading: areasIsLoading,
  } = useGetAreaDropdownQuery({
    subZoneId: (taskDataById?.data as any)?.subZoneId,
  });

  const [area, setArea] = useState(null);

  const handleAreaChange = (value: any) => {
    setArea(value);
  };

  const {
    data: subAreas,
    error: subAreasError,
    isLoading: subAreasIsLoading,
  } = useGetSubAreaDropdownQuery({
    areaId: area ?? (taskDataById?.data as any)?.areaId,
  });

  const {
    data: propertyTypeUsages,
    error: propertyTypeUsagesError,
    isLoading: propertyTypeUsagesIsLoading,
  } = useGetPropertyTypeUsageDropdownQuery({
    propertyTypeId: (taskDataById?.data as any)?.propertyTypeId,
  });

  const {
    data: actualPropertyTypeUsages,
    error: actualPropertyTypeUsagesError,
    isLoading: actualPropertyTypeUsagesIsLoading,
  } = useGetPropertyTypeUsageDropdownQuery({
    propertyTypeId: (taskDataById?.data as any)?.allottedPropertyTypeId
      ? (taskDataById?.data as any)?.allottedPropertyTypeId
      : (taskDataById?.data as any)?.propertyTypeId,
  });

  const handleAllotedUseChange = (value: string) => {
    form.setFieldValue("allottedUsageOther", "");
    const filteredAllotteduse =
      actualPropertyTypeUsages &&
      actualPropertyTypeUsages?.data?.find((usage) => {
        return usage.id === value;
      });
    setAllottedUse(filteredAllotteduse?.name);
  };

  const handleCurrentUseChange = (value: string) => {
    form.setFieldValue("currenUsageOther", "");
    const filteredCurrentuse =
      propertyTypeUsages &&
      propertyTypeUsages?.data?.find((usage) => {
        return usage.id === value;
      });
    setCurrentUse(filteredCurrentuse?.name);
  };

  const handleViolationChange = (value: string[]) => {
    setSelectedViolation(
      violations?.data
        .filter((val) => value.includes(val.id))
        .map((val) => val.name)
    );
  };

  useEffect(() => {
    if (taskDataById) {
      const task = taskDataById.data;
      setAllottedUse((task as any)?.allottedUsage);
      setCurrentUse((task as any)?.currentUsage);
      setPropertyType((task as any)?.propertyType);

      const { remarks, ...otherTaskFields } = task;
      form.setFieldsValue({
        ...otherTaskFields,
        ...((task as any)?.certificateOfCompletion
          ? {
              certificateOfCompletion: moment(
                (task as any)?.certificateOfCompletion
              ),
            }
          : {}),
        ...((task as any)?.dateOfFirstAllotment
          ? {
              dateOfFirstAllotment: moment((task as any)?.dateOfFirstAllotment),
            }
          : {}),
        ...((task as any)?.dateOfLastTransfer
          ? { dateOfLastTransfer: moment((task as any)?.dateOfLastTransfer) }
          : {}),
        ...((task as any)?.leaseEndingDate
          ? { leaseEndingDate: moment((task as any)?.leaseEndingDate) }
          : {}),
        ...((task as any)?.buildingPlanApprovalDate
          ? {
              buildingPlanApprovalDate: moment(
                (task as any)?.buildingPlanApprovalDate
              ),
            }
          : {}),
        ...((task as any)?.completionCertificateDate
          ? {
              completionCertificateDate: moment(
                (task as any)?.completionCertificateDate
              ),
            }
          : {}),

        dueDate: moment(task.dueDate),
      });

      if (task.buildingCoordinates) {
        setPolygonData(task.buildingCoordinates);
      }
    }
  }, [taskDataById, form]);

  useEffect(() => {
    if (violations && taskDataById)
      setSelectedViolation(
        violations?.data
          .filter((val) =>
            (taskDataById?.data as any)?.violationIds?.includes(val.id)
          )
          .map((val) => val.name)
      );
  }, [violations, taskDataById]);

  const convertFileToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!file || !(file instanceof File)) {
        return reject(new Error("Invalid file input"));
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        let base64String = reader.result as string;
        const sanitizedFileName = file.name
          .replace(/\s+/g, "_")
          .replace(/[^\w.-]/g, "");
        const fileExtension = sanitizedFileName.split(".").pop();
        const isSpecialDoc =
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/pdf";

        if (isSpecialDoc && fileExtension) {
          const base64Data = base64String.split(",")[1];
          base64String = `data:application/${fileExtension};base64,${base64Data}`;
        }

        resolve(base64String);
      };
      reader.onerror = (error) =>
        reject(new Error("File reading failed: " + error));
    });
  };

  const handlePreview = async (file: ExtendedUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await convertFileToBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
    setUploadError("");
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const getFileIcon = (file: any) => {
    const isImage = /\.(png|jpe?g|gif)$/i.test(file.url || file.name);
    const isDocument = /\.(pdf|docx?|xlsx?)$/i.test(file.url || file.name);

    if (isImage) {
      return (
        <EyeOutlined
          style={{ fontSize: "16px", color: "#1890ff" }}
          onClick={() => handlePreview(file)}
        />
      );
    } else if (isDocument) {
      return (
        <DownloadOutlined
          style={{ fontSize: "16px", color: "#1890ff" }}
          onClick={() => window.open(file.url, "_blank")}
        />
      );
    } else {
      return <FileOutlined style={{ fontSize: "32px", color: "#1890ff" }} />;
    }
  };

  const customItemRender = (originNode: any, file: any) => {
    const isNewUpload = /\.(png|jpe?g|gif|pdf|docx?|xlsx?)$/i.test(file.name);

    return (
      <div>
        <div
          style={{
            width: "100px",
            height: "90px",
          }}
        >
          {originNode}
        </div>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #e8e8e8",
            marginTop: "8px",
            paddingTop: "8px",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {getFileIcon(file)}
          {isNewUpload && (
            <DeleteOutlined
              style={{ fontSize: "16px", color: "red" }}
              onClick={() => handleRemove(file)}
            />
          )}
        </div>
      </div>
    );
  };

  const validateUpload = () => {
    if (fileList.length === 0) {
      setUploadError("Please upload at least one image.");
      return false; // Prevent submission
    } else {
      setUploadError("");
      return true; // Proceed with submission
    }
  };

  const onFinish = async (values: any) => {
    if (validateUpload()) {
      try {
        for (const key of Object.keys(values)) {
          if (!values[key] || key == "propertyType") values[key] = undefined;
        }

        const attachments = await Promise.all(
          fileList.map(async (file) => {
            if (!file.url) {
              const base64File = await convertFileToBase64(file.originFileObj);
              return {
                remarks: values?.remarks,
                documentTypes: values?.actionType && values?.actionType,
                file: base64File,
                lat: undefined,
                long: undefined,
              };
            }
            return null;
          })
        );

        const filteredAttachments = attachments.filter(
          (attachment) => attachment !== null
        );

        await editTask({
          id,
          body: {
            ...values,
            actionType: undefined,
            remarks: undefined,
            actualUsageOther: values?.allottedUsageOther,
            allottedUsageOther: undefined,
            allottedUsageId: undefined,
            actualUsageId: values.allottedUsageId,
            dueDate: values.dueDate.format("YYYY-MM-DD"),
            status: "proceeded",
            attachments: filteredAttachments ? filteredAttachments : undefined,
          },
        }).unwrap();

        notification.success({
          message: "Survey processed successfully",
          placement: "topRight",
          duration: 3,
        });

        onClose && onClose();
      } catch (err) {
        console.error("Survey processed failed:", err);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (taskDataById) {
      const task: Task = taskDataById.data;

      if (task.buildingCoordinates) {
        setPolygonData(task.buildingCoordinates);
      }
      if (task && task.attachments) {
        setSurveyImages((task as any).attachments || []);

        if (task && task.remarks) {
          setRemarksArray((task as any).remarks || []);
        }
        if (task && task.attachments) {
          const initialPropertyFileList: any[] = [];
          const initialDocumentFileList: any[] = [];
          const initialNoticeFileList: any[] = [];
          const initialNoticeReplyFileList: any[] = [];
          const initialShowCauseFileList: any[] = [];
          const initialShowCauseReplyFileList: any[] = [];
          const initialCloseFileList: any[] = [];
          const initialSealFileList: any[] = [];
          const initialDisposalFileList: any[] = [];
          const initialChallanFileList: any[] = [];
          const initialDemolationFileList: any[] = [];
          const initialDesealFileList: any[] = [];
          const initialDescalingFileList: any[] = [];

          task.attachments.forEach((attachment: any, index: number) => {
            const commonData = {
              uid: `-1-${index}`,
              name: attachment.name || `Attachment-${index + 1}`,
              url: attachment.path ? `${API_BASE_URL}${attachment.path}` : "",
            };

            switch (attachment?.documentTyp) {
              case "Attachments":
                initialPropertyFileList.push(commonData);
                break;
              case "PropertyDocuments":
                initialDocumentFileList.push(commonData);
                break;
              case "Notice":
                initialNoticeFileList.push(commonData);
                break;
              case "NoticeReply":
                initialNoticeReplyFileList.push(commonData);
                break;
              case "ShowCause":
                initialShowCauseFileList.push(commonData);
                break;
              case "ShowCauseReply":
                initialShowCauseReplyFileList.push(commonData);
                break;
              case "Challan/Fee":
                initialChallanFileList.push(commonData);
                break;
              case "DisposeOff":
                initialDisposalFileList.push(commonData);
                break;
              case "Closed":
                initialCloseFileList.push(commonData);
                break;
              case "Seal":
                initialSealFileList.push(commonData);
                break;
              case "Demolation":
                initialDemolationFileList.push(commonData);
                break;
              case "De-seal":
                initialDesealFileList.push(commonData);
                break;
              case "DescalingFee":
                initialDescalingFileList.push(commonData);
                break;
              default:
                break;
            }
          });

          setPropertyFileList(initialPropertyFileList);
          setDocumentFileList(initialDocumentFileList);
          setNoticeFileList(initialNoticeFileList);
          setNoticeReplyFileList(initialNoticeReplyFileList);
          setShowcauseFileList(initialShowCauseFileList);
          setShowcauseReplyFileList(initialShowCauseReplyFileList);
          setChallanFileList(initialChallanFileList);
          setSealFileList(initialSealFileList);
          setDisposalFileList(initialDisposalFileList);
          setCloseFileList(initialCloseFileList);
          setDemolationFileList(initialDemolationFileList);
          setDesealFileList(initialDesealFileList);
          setDescalingFileList(initialDescalingFileList);
        }
      }

      if (task.lat && task.long) {
        setMapCenter({
          lat: parseFloat(task.lat),
          lng: parseFloat(task.long),
        });
      }
    }
  }, [taskDataById, form]);

  const center = {
    lat: 33.6938,
    lng: 73.0652,
  };

  const imageMarkers: ImageMarker[] = surveyImages
    .filter((attachment) => attachment.documentTyp === "Attachments")
    .map((attachment) => ({
      coordinates: {
        lat: parseFloat(attachment.lat),
        lng: parseFloat(attachment.long),
      },
      images: attachment.path ? `${API_BASE_URL}${attachment.path}` : "",
    }));

  const uploadButton = (
    <button
      style={{ border: 0, background: "none", marginTop: "10px" }}
      type="button"
    >
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const actionOptions = [
    {
      id: "Notice",
      name: "Notice",
    },
    {
      id: "NoticeReply",
      name: "Notice Reply",
    },
    {
      id: "ShowCause",
      name: "Show Cause Notice",
    },
    {
      id: "ShowCauseReply",
      name: "Show Cause Notice Reply",
    },
    {
      id: "Challan/Fee",
      name: "Challan/Fee",
    },
    {
      id: "Closed",
      name: "Close",
    },
    {
      id: "Seal",
      name: "Seal",
    },
    {
      id: "DescalingFee",
      name: "Descaling Fee",
    },
    {
      id: "De-seal",
      name: "De-seal",
    },
    {
      id: "Demolition",
      name: "Demolation",
    },
    {
      id: "DisposeOff",
      name: "Dispose Off",
    },
  ];

  const ImagePreviewSection: React.FC<ImagePreviewSectionProps> = ({
    fileList,
    handlePreview,
    previewOpen,
    handleCancel,
    previewImage,
    customItemRender,
  }) => (
    <>
      {fileList.length >= 1 && (
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              beforeUpload={() => false}
              showUploadList={{
                showPreviewIcon: false,
                showDownloadIcon: false,
                showRemoveIcon: false,
              }}
              itemRender={(originNode, file) =>
                customItemRender(originNode, file)
              }
            />
            <Modal
              open={previewOpen}
              onCancel={handleCancel}
              bodyStyle={{ padding: "20px", backgroundColor: "white" }}
              footer={null}
              closeIcon={
                <CloseOutlined
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    borderRadius: "10%",
                    padding: "5px",
                  }}
                />
              }
            >
              <img
                alt="attachment"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </Row>
        </Col>
      )}
    </>
  );

  return (
    <AppFormWrapper
      error={
        error ||
        violationsError ||
        areasError ||
        subAreasError ||
        taskDataError ||
        propertyTypeUsagesError ||
        actualPropertyTypeUsagesError
      }
      initialValues={{ remember: true }}
      isLoading={isLoading || taskDataIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Proceed"
      form={form}
    >
      <Row gutter={[16, 16]} className="h-full">
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <MapWithImages
            center={mapCenter || center}
            imageMarkers={imageMarkers}
            isLoaded={true}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item label="Violations" name="violationIds">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select violations"
              optionFilterProp="children"
              className="rounded-md p-1 w-full"
              loading={violationsIsLoading}
              // disabled={isLoading}
              disabled
              filterOption={(input, option) =>
                option?.label
                  ?.toString()
                  .toLowerCase()
                  .includes(input.toLowerCase()) ?? false
              }
              onChange={handleViolationChange}
            >
              {violations?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {propertyType !== "Illegal" && (
            <Form.Item label="Alloted Use" name="allottedUsageId">
              <Select
                showSearch
                placeholder="Select Alloted Use"
                optionFilterProp="children"
                className="rounded-md p-1 h-10 w-full"
                loading={actualPropertyTypeUsagesIsLoading}
                // disabled={actualPropertyTypeUsagesIsLoading || isLoading}
                disabled
                filterOption={(input, option) =>
                  option?.label
                    ?.toString()
                    .toLowerCase()
                    .includes(input.toLowerCase()) ?? false
                }
                onChange={handleAllotedUseChange}
              >
                {actualPropertyTypeUsages?.data?.map((value) => (
                  <Option key={value.id} value={value.id}>
                    {value.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {allottedUse === "Other" && (
            <Form.Item label="Other Allotted Use" name="allottedUsageOther">
              <Input
                // disabled={isLoading}
                disabled
                className="rounded-md p-2 w-full"
                placeholder="Enter Other Allotted Use"
                maxLength={30}
              />
            </Form.Item>
          )}

          <Form.Item label="Current Use" name="currentUsageId">
            <Select
              showSearch
              placeholder="Select Current use"
              optionFilterProp="children"
              className="rounded-md p-1 h-10 w-full"
              loading={propertyTypeUsagesIsLoading}
              // disabled={propertyTypeUsagesIsLoading || isLoading}
              disabled
              filterOption={(input, option) =>
                option?.label
                  ?.toString()
                  .toLowerCase()
                  .includes(input.toLowerCase()) ?? false
              }
              onChange={handleCurrentUseChange}
            >
              {propertyTypeUsages?.data?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {currentUse === "Other" && (
            <Form.Item label="Other Current Use" name="currenUsageOther">
              <Input
                // disabled={isLoading}
                disabled
                className="rounded-md p-2 w-full"
                placeholder="Enter Other Current Use"
                maxLength={30}
              />
            </Form.Item>
          )}

          <Form.Item
            label="Survey Date"
            name="dueDate"
            rules={taskValidator.dueDate}
          >
            <DatePicker
              className="rounded-md p-1 w-full"
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
              disabled
            />
          </Form.Item>

          <Form.Item label="Inspector Remarks" name="inspectorRemarks">
            <Input
              disabled
              style={{ color: "black" }}
              className="rounded-md p-2 w-full"
              placeholder="Inspector Remarks"
              maxLength={30}
            />
          </Form.Item>
          <Col span={24}>
            <Divider />
          </Col>
          <Form.Item
            label="Document/Action Type"
            name="actionType"
            rules={taskValidator.actionType}
          >
            <Select
              showSearch
              placeholder="Select Action"
              optionFilterProp="children"
              className="rounded-md p-1 h-10 w-full"
            >
              {actionOptions?.map((value) => (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Remarks" name="remarks">
            <TextArea
              placeholder="Enter Remarks"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Form.Item>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeUpload={() => false}
                  showUploadList={{
                    showPreviewIcon: false,
                    showDownloadIcon: false,
                    showRemoveIcon: false,
                  }}
                  itemRender={(originNode, file) =>
                    customItemRender(originNode, file)
                  }
                  accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                >
                  {fileList.length >= 4 ? null : uploadButton}
                </Upload>{" "}
                {uploadError && (
                  <div style={{ color: "red" }}>{uploadError}</div>
                )}{" "}
                {/* Show error message */}
              </Form.Item>

              <Modal
                open={previewOpen}
                onCancel={handleCancel}
                bodyStyle={{ padding: "20px" }} // Apply padding to the modal content (body)
                footer={null}
                closeIcon={
                  <CloseOutlined
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0)", // Change background color here
                      color: "white", // Change icon color to contrast with background
                      borderRadius: "10%", // Make the icon background circular
                      padding: "5px", // Add padding around the icon
                    }}
                  />
                }
              >
                <img
                  alt="attachment"
                  style={{ width: "100%", marginBottom: "2rem" }}
                  src={previewImage}
                />
              </Modal>
            </Row>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item label="Property Type" name="propertyType">
            <Input
              className="rounded-md p-2 w-full"
              placeholder="Enter Property Type"
              readOnly
              maxLength={30}
              disabled
            />
          </Form.Item>
          {selectedViolation && selectedViolation.includes("Set Back") && (
            <Form.Item
              label="Setback Violation (Feet)"
              name="violationfeet"
              rules={[
                {
                  pattern: /^[0-9]*$/,
                  message: "Only numeric values are allowed.",
                },
              ]}
            >
              <Input
                maxLength={30}
                className="rounded-md p-1 w-full"
                // disabled={isLoading}

                disabled
                placeholder="Enter numeric input"
              />
            </Form.Item>
          )}

          {selectedViolation &&
            selectedViolation.includes("F.A.R. Violation") && (
              <>
                <Form.Item
                  label="Approved Covered Area"
                  name="violationApprovedCoveredArea"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    // disabled={isLoading}
                    disabled
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
                <Form.Item
                  label="Existing Covered Area"
                  name="violationExisitingCoveredArea"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    // disabled={isLoading}
                    disabled
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
                <Form.Item
                  label="Access Area"
                  name="violationAccessArea"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    // disabled={isLoading}
                    disabled
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
                <Form.Item
                  label="Approved Number of Floors"
                  name="violationApprovedNumberOfFloor"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    // disabled={isLoading}
                    disabled
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
                <Form.Item
                  label="Built Number of Floors"
                  name="violationBuiltNumberOfFloors"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only numeric values are allowed.",
                    },
                  ]}
                >
                  <Input
                    maxLength={30}
                    className="rounded-md p-1 w-full"
                    // disabled={isLoading}
                    disabled
                    placeholder="Enter numeric input"
                  />
                </Form.Item>
              </>
            )}
        </Col>
      </Row>
      <Col span={24}>
        <Divider />
      </Col>
      {imageSections.map((section, index) => {
        const remarks = remarksArray.filter(
          (remarks) => remarks.documentType == section.type
        );

        return section.fileList && section.fileList.length > 0 ? (
          <React.Fragment key={index}>
            <div>
              {<h2 style={{ fontWeight: "bold" }}>{section.title}</h2>}
              {remarks.length > 0 && (
                <h3>Uploaded By: {remarks[0].userName}</h3>
              )}
              {remarks.length > 0 && <h3>Remarks: {remarks[0].remarks}</h3>}
              {remarks.length > 0 && (
                <h3>
                  Upload Date:{" "}
                  {remarks[0]?.createdAt
                    ? moment(remarks[0].createdAt).format("DD-MM-YYYY")
                    : "No Date Available"}
                </h3>
              )}
            </div>

            <ImagePreviewSection
              fileList={section.fileList}
              handlePreview={handlePreview}
              previewOpen={previewOpen}
              handleCancel={handleCancel}
              previewImage={previewImage}
              customItemRender={customItemRender}
            />
            <Col span={24}>
              <Divider />
            </Col>
          </React.Fragment>
        ) : null;
      })}
    </AppFormWrapper>
  );
};

export default EditTask;
