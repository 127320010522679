import AddUser from "@components/Users/addUser";
import AppPageWrapper from "@components/appPageWrapper";
import EditUser from "@components/Users/editUser";
import UserList from "@components/Users/userList";
import React from "react";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import ViewUser from "@components/Users/viewUser";

const UserManagement: React.FC = () => {
    const { hasPermission } = usePermissions();
    return hasPermission(permissions.VIEW_USER_MANAGEMENT) ? (
        <AppPageWrapper
            addComp={() => hasPermission(permissions.ADD_USER) ? <AddUser /> : null}
            listComp={<UserList />}
            editComp={(id: string) => <EditUser id={id} />}
            viewComp={(id: string) => <ViewUser id={id} />}
            moduleName="User"
            pagetTitle="User Management"
        />
    ) : null;
};

export default UserManagement;
