import { Rule } from "antd/es/form";

interface TaskValidator {
  buildingHeight: Rule[];
  plotWidth: Rule[];
  plotLength: Rule[];
  phoneNo: Rule[];
  ownerName: Rule[];
  streetName: Rule[];
  propertyName: Rule[];
  propertyNo: Rule[];
  dueDate: Rule[];
  inspectorId: Rule[];
  floorAreaSft: Rule[];
  floorAreaSqy: Rule[];
  attachments: Rule[];
  noOfStories: Rule[];
  floorArlandUseConversionAndRegularizationChargeseaSqy: Rule[];
  actionType: Rule[];
}

export const taskValidator: TaskValidator = {
  buildingHeight: [
    { required: false, message: "Please enter building height" },
    { type: "number", message: "buildingHeight must be a number" },
  ],
  plotWidth: [
    { required: false, message: "Please enter plot width" },
    { type: "number", message: "Plot width must be in numbers" },
  ],
  plotLength: [
    { required: false, message: "Please enter plot lenght" },
    { type: "number", message: "Plot length must be in numbers" },
  ],
  phoneNo: [
    { required: false, message: "Please enter phone number" },
    { type: "number", message: "Phone number must be in numbers" },
  ],
  ownerName: [{ required: false, message: "Please enter owner name" }],
  streetName: [{ required: false, message: "Please enter street name" }],
  propertyName: [{ required: false, message: "Please enter property name" }],
  propertyNo: [
    { required: false, message: "Please enter property number" },
    { type: "number", message: "Property name must be in number" },
  ],
  dueDate: [
    { required: false, message: "Please enter Date" },
    { type: "date", message: "Please enter the date" },
  ],
  inspectorId: [
    { required: true, message: "Please select the person to assign the task" },
    { type: "number", message: "Please select the person to assign the task" },
  ],
  floorAreaSft: [
    { required: false, message: "Please enter floor area in Sft" },
    { type: "date", message: "Please enter floor area in Sft" },
  ],
  floorAreaSqy: [
    { required: false, message: "Please enter floor area in Sq.Yard" },
    { type: "date", message: "Please enter floor area in Sq.Yard" },
  ],
  noOfStories: [{ required: false, message: "Please enter number of stories" }],
  floorArlandUseConversionAndRegularizationChargeseaSqy: [
    {
      required: false,
      message:
        "Please enter the charges for land use conversion and regularization",
    },
  ],
  attachments: [
    { required: true, message: "Please upload the property attachments" },
  ],
  actionType: [
    { required: true, message: "Please select Document/Action Type" },
  ],
};
