import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { PaginationPayload, Response, ResponseListPaginate, Task } from "types";
import apiRoutes from "../apiRoutes";
import apiTags from "@services/apiTags";

export const taskApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getTasks: builder.query<ResponseListPaginate<Task>, PaginationPayload>({
    query: ({ page, limit, searchText, documentType }: PaginationPayload) => {
      const params: PaginationPayload = { page, limit };

      if (searchText) {
        params.searchText = searchText;
      }
      if (documentType) {
        params.documentType = documentType;
      }
      return {
        url: apiRoutes.getTasks,
        params,
      };
      // url: apiRoutes.getTasks,
      // params: { page, limit },
    },
    providesTags: [apiTags.task],
  }),
  createTask: builder.mutation<Response<Task>, Partial<Task>>({
    query: (body) => ({
      url: apiRoutes.createTask,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }),
    invalidatesTags: [apiTags.task],
  }),
  getTaskById: builder.query<Response<Task>, string>({
    query: (id: string) => apiRoutes.getTaskById(id),
    providesTags: [apiTags.task],
  }),
  editTask: builder.mutation<
    Response<Task>,
    { id: string; body: Partial<Task> }
  >({
    query: ({ id, body }) => ({
      url: apiRoutes.editTaskById(id),
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    }),
    invalidatesTags: [apiTags.task],
  }),
  deleteTask: builder.mutation<Response<void>, string>({
    query: (id: string) => ({
      url: apiRoutes.deleteTask(id),
      method: "DELETE",
    }),
    invalidatesTags: [apiTags.task],
  }),
  printChallan: builder.mutation<Response<void>, string>({
    query: (id: string) => ({
      url: apiRoutes.printChallanTask(id),
      method: "POST",
    }),
    invalidatesTags: [apiTags.task],
  }),
  finalDisposal: builder.mutation<Response<void>, string>({
    query: (id: string) => ({
      url: apiRoutes.finalDisposalTask(id),
      method: "POST",
    }),
    invalidatesTags: [apiTags.task],
  }),
});
