import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Upload, message, Image } from "antd";
import {
  useGetDesignationsQuery,
  useGetDepartmentQuery,
  useGetDivisionQuery,
  useGetZoneDropdownQuery,
  useGetSupervisorQuery,
  useGetRoleDropdownQuery,
  useGetUserByIdQuery,
  useEditUserMutation,
  useGetAreaDropdownQuery,
  useGetSubZoneDropdownQuery,
  useGetSubAreaDropdownQuery,
} from "@services/api";
import { UploadOutlined } from "@ant-design/icons";
import AppFormWrapper from "@components/appFormWrapper";
import { User } from "types";
import { userValidator } from "@validators/userValidator";
import { API_BASE_URL } from "../../../constants/index";
import defaultImg from "../../../assets/img/defaultImage.jpeg";
import NotificationHandler from "@components/notificationHandler";
const { Option } = Select;

interface EditUserProps {
  onClose?: () => void;
  id: string;
}

const EditUser: React.FC<EditUserProps> = ({ onClose, id }) => {
  const [form] = Form.useForm();
  const { openNotificationWithIcon } = NotificationHandler();
  const [editUser, { isLoading, error }] = useEditUserMutation();

  const {
    data: userDataById,
    error: userDataError,
    isLoading: userDataIsLoading,
    refetch: refetch,
  } = useGetUserByIdQuery(id);

  const [pictureBase64, setPictureBase64] = useState<string | null>(null);
  const [selectedZoneId, setSelectedZoneId] = useState<string | null>("");
  const [hierarchy, setHierarchy] = useState<string | undefined>("");

  const [selectedSubZoneId, setSelectedSubZoneId] = useState<string | null>("");
  const [selectedAreaId, setSelectedAreaId] = useState<string | null>("");
  const {
    data: designations,
    error: designationsError,
    isLoading: designationsIsLoading,
  } = useGetDesignationsQuery();
  const {
    data: department,
    error: departmentError,
    isLoading: departmentIsLoading,
  } = useGetDepartmentQuery();
  const {
    data: division,
    error: divisionError,
    isLoading: divisionIsLoading,
  } = useGetDivisionQuery();
  const {
    data: zone,
    error: zoneError,
    isLoading: zoneIsLoading,
    refetch: refetchZone,
  } = useGetZoneDropdownQuery();

  const {
    data: subZone,
    error: subZoneError,
    isLoading: subZoneIsLoading,
    refetch: refetchSubZone,
  } = useGetSubZoneDropdownQuery({ zoneId: selectedZoneId as string });
  const {
    data: supervisor,
    error: supervisorError,
    isLoading: supervisorIsLoading,
  } = useGetSupervisorQuery({ hierarchy: hierarchy as string });
  const {
    data: role,
    error: roleError,
    isLoading: roleIsLoading,
  } = useGetRoleDropdownQuery({ hierarchy: hierarchy as string });

  const {
    data: area,
    isLoading: areaIsLoading,
    refetch: refetchAreas,
    isUninitialized,
  } = useGetAreaDropdownQuery({ subZoneId: selectedSubZoneId as string });

  const {
    data: subAreaData,
    error: subAreasError,
    isLoading: subAreasIsLoading,
    refetch: refetchSubAreas,
  } = useGetSubAreaDropdownQuery({ areaId: selectedAreaId as string });

  const handleCloseDrawer = () => {
    if (userDataById) {
      const user: User = userDataById?.data;
      form.setFieldsValue({
        name: user.name,
        email: user.email,
        CNIC: user.CNIC,
        phone: user.phone,
        designationId: user.designationId,
        departmentId: user.departmentId,
        divisionId: user.divisionId,
        zoneId: user.zoneId,
        subZoneId: user.subZoneId,
        supervisorId: user.supervisorId,
        roleId: user.roleId,
        areasIds: user.areasIds,
        subAreasIds: user.subAreasIds,
      });
    }
    onClose && onClose();
  };

  useEffect(() => {
    if (userDataById) {
      const user: User = userDataById?.data;
      setHierarchy(user?.hierarchy);
      form.setFieldsValue({
        name: user.name,
        email: user.email,
        CNIC: user.CNIC,
        phone: user.phone,
        designationId: user.designationId,
        departmentId: user.departmentId,
        divisionId: user.divisionId,
        zoneId: user.zoneId,
        subZoneId: user.subZoneId,
        supervisorId: user.supervisorId,
        roleId: user.roleId,
        areasIds: user.areasIds,
        subAreasIds: user.subAreasIds,
      });
    }
  }, [userDataById, form]);

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleUploadChange = async ({ fileList }: any) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const base64 = await getBase64(file);
      setPictureBase64(base64);
    } else {
      setPictureBase64(null);
    }
  };

  const validateFileType = (file: File) => {
    const isValidType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";

    if (!isValidType) {
      message.error("You can only upload image files (PNG, JPEG, JPG, GIF)!");
    }
    return isValidType || Upload.LIST_IGNORE; // Will ignore the file if not valid
  };

  const initialValues = {
    name: "",
    email: "",
    CNIC: "",
    phone: "",
    picture: undefined,
    designationId: undefined,
    departmentId: undefined,
    divisionId: undefined,
    zoneId: undefined,
    subZoneId: undefined,
    areasIds: undefined,
    subAreasIds: undefined,
    supervisorId: undefined,
    roleId: undefined,
  };
  const onFinish = async (values: any) => {
    try {
      delete values.confirmPassword;

      const payload: User = {
        name: values.name || undefined,
        email: values.email || undefined,
        CNIC: values.CNIC || undefined,
        phone: values.phone || undefined,
        designationId: values.designationId || undefined,
        departmentId: values.departmentId || undefined,
        divisionId: values.divisionId || undefined,
        zoneId: values.zoneId || undefined,
        subZoneId: values.subZoneId || undefined,
        roleId: values.roleId || undefined,
        supervisorId: values.supervisorId || undefined,
        picture: pictureBase64 || undefined,

        areasIds: values.areasIds || undefined,
        subAreasIds: values.subAreasIds || undefined,
      };

      const result = await editUser({ id, body: payload }).unwrap();
      openNotificationWithIcon(
        "success",
        result?.message || "User updated successfully!"
      );
      onClose && onClose();
    } catch (err: any) {
      console.error("Update failed:", err);
      // openNotificationWithIcon("error", err?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleZoneChange = (newZoneId: string | null) => {
    setSelectedZoneId(newZoneId);
    if (newZoneId && !isUninitialized) {
      refetchSubZone();
    }
    form.resetFields(["subZoneId", "areasIds", "subAreasIds"]);
  };
  const handleSubZoneChange = (newSubZoneId: string | null) => {
    setSelectedSubZoneId(newSubZoneId);
    if (newSubZoneId && !isUninitialized) {
      refetchAreas();
    }
    form.resetFields(["areasIds", "subAreasIds"]);
  };

  const handleAreaChange = (newAreaIds: string | null) => {
    setSelectedAreaId(newAreaIds);

    if (!isUninitialized) {
      refetchSubAreas();
    }

    const currentSubAreasIds = form.getFieldValue("subAreasIds") || [];
    const validSubAreasIds =
      subAreaData?.data
        .filter((subArea: any) => newAreaIds?.includes(subArea?.areaId))
        .map((subArea: any) => subArea?.id) || [];
    const updatedSubAreasIds = currentSubAreasIds.filter((subAreaId: string) =>
      validSubAreasIds.includes(subAreaId)
    );
    form.setFieldsValue({
      subAreasIds: updatedSubAreasIds,
    });
  };

  const handleDesignationChange = (value: number) => {
    form.setFieldsValue({
      roleId: undefined,
      supervisorId: undefined,
    });
    const filteredDesignation =
      designations &&
      designations?.data?.find((item) => {
        return item?.id === value;
      });
    if (filteredDesignation) {
      setHierarchy(filteredDesignation?.hierarchy?.toString());
    }
  };

  return (
    <AppFormWrapper
      form={form}
      error={error}
      initialValues={initialValues}
      isLoading={isLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item label="Name" name="name" rules={userValidator.name}>
        <Input
          maxLength={30}
          placeholder="Enter Your Name"
          className="rounded-md p-1"
          disabled={isLoading}
        />
      </Form.Item>

      <Form.Item label="Email" name="email" rules={userValidator.email}>
        <Input
          maxLength={25}
          placeholder="Enter Your Email"
          className="rounded-md p-1"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item label="CNIC" name="CNIC" rules={userValidator.cnic}>
        <Input
          maxLength={13}
          placeholder="Enter Your CNIC in digits only"
          className="rounded-md p-1"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item label="Phone Number" name="phone" rules={userValidator.phone}>
        <Input
          maxLength={11}
          placeholder="Enter Your Phone number in digits only"
          className="rounded-md p-1"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item
        className="flex flex-col"
        label="Picture"
        name="picture"
        rules={userValidator.picture}
      >
        <div className="flex flex-col gap-1">
          {pictureBase64 || userDataById?.data?.picture ? (
            <Image
              width={60}
              src={pictureBase64 || API_BASE_URL + userDataById?.data?.picture}
              alt="User Picture"
            />
          ) : null}
          <Upload
            onChange={handleUploadChange}
            maxCount={1}
            accept="image/png, image/gif, image/jpeg, image/jpg"
            beforeUpload={validateFileType}
          >
            <Button
              disabled={isLoading}
              icon={<UploadOutlined />}
              className="custom-upload-button p-1"
            >
              Click to Upload Image
            </Button>
          </Upload>
        </div>
      </Form.Item>

      <Form.Item
        label="Designation"
        name="designationId"
        rules={userValidator.designationId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select your designation"
          loading={designationsIsLoading}
          disabled={designationsIsLoading || isLoading}
          className="rounded-md"
          onChange={handleDesignationChange}
        >
          {designations?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Role" name="roleId" rules={userValidator.roleId}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select your role"
          loading={roleIsLoading}
          disabled={!hierarchy || roleIsLoading || isLoading}
          className="rounded-md"
        >
          {role?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Supervisor"
        name="supervisorId"
        rules={userValidator.supervisorId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select your supervisor"
          loading={supervisorIsLoading}
          disabled={!hierarchy || supervisorIsLoading || isLoading}
          className="rounded-md"
        >
          {supervisor?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Directorate"
        name="departmentId"
        rules={userValidator.departmentId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select your Directorate"
          loading={departmentIsLoading}
          disabled={departmentIsLoading || isLoading}
          className="rounded-md"
        >
          {department?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Zone" name="zoneId" rules={userValidator.zoneId}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select your zone"
          loading={zoneIsLoading}
          disabled={zoneIsLoading || isLoading}
          className="rounded-md"
          onChange={handleZoneChange}
        >
          {zone?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Sub Zone"
        name="subZoneId"
        rules={userValidator.subZoneId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select your sub zone"
          loading={zoneIsLoading}
          disabled={zoneIsLoading || isLoading}
          className="rounded-md"
          onChange={handleSubZoneChange}
        >
          {subZone?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Area" name="areasIds" rules={userValidator.areasIds}>
        <Select
          showSearch
          optionFilterProp="children"
          mode="multiple"
          allowClear
          placeholder="Please select"
          loading={areaIsLoading}
          disabled={areaIsLoading || isLoading}
          onChange={handleAreaChange}
        >
          {area?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Sub Area"
        name="subAreasIds"
        rules={userValidator.subAreasIds}
      >
        <Select
          showSearch
          optionFilterProp="children"
          mode="multiple"
          allowClear
          placeholder="Please select sub area"
          loading={areaIsLoading}
          disabled={areaIsLoading || isLoading}
        >
          {subAreaData?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default EditUser;
