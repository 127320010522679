import React, { ReactNode, useCallback, useState } from "react";
import { Button, Select, Spin } from "antd";
import { EditOutlined, EyeOutlined, AuditOutlined } from "@ant-design/icons";
import AppDrawer from "@components/appDrawer";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "@constants/index";
import styles from "./appPageWrapper.module.css";
import { Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

interface AppPageWrapperProps {
  addComp?: (isLoaded: boolean) => ReactNode;
  listComp?: ReactNode;
  pagetTitle: string;
  moduleName: string;
  editComp?: (id: string, isLoaded: boolean) => ReactNode;
  width?: string;
  viewComp?: (id: string, isLoaded: boolean) => ReactNode;
  userProfile?: (id: string, isLoaded: boolean) => ReactNode;
  proceedComp?: (id: string, isLoaded: boolean) => ReactNode;
}

interface ChildWithEditComponentProp {
  editComp?: (id: string) => ReactNode;
  viewComp?: (id: string) => ReactNode;
  userProfile?: (id: string) => ReactNode;
  proceedComp?: (id: string, isDisable: number) => ReactNode;
  isLoaded: boolean;
  searchValue?: string;
  statusValue?: string;
  noticeType?: string;
}

const AppPageWrapper: React.FC<AppPageWrapperProps> = ({
  addComp,
  listComp,
  pagetTitle,
  moduleName,
  editComp,
  width,
  viewComp,
  userProfile,
  proceedComp,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["drawing", "places"],
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const [statusValue, setStatusValue] = useState<string>("");
  const [noticeType, setNoticeType] = useState<string>("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  console.log("documentType", noticeType);
  const debounceSearch = useCallback(
    debounce(async (value: string) => {
      setLoading(true);
      // Simulate API call here (you can replace this with your actual API call)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setDebouncedSearchValue(value);
      setLoading(false);
    }, 1000),
    []
  );
  // Update the search value and debounce the search
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    debounceSearch(value);
  };
  const renderChildrenWithProps = () => {
    return React.Children.map(listComp, (child) => {
      if (React.isValidElement<ChildWithEditComponentProp>(child)) {
        return React.cloneElement(child, {
          isLoaded,
          searchValue: debouncedSearchValue,
          statusValue,
          noticeType,
          editComp: (id: string) => (
            <AppDrawer
              title={`Edit ${moduleName}`}
              toggler={(showDrawer) => (
                <Tooltip title={`Edit ${moduleName}`}>
                  <EditOutlined
                    onClick={() => {
                      showDrawer();
                    }}
                    className="text-sm md:text-lg"
                  />
                </Tooltip>
              )}
              width={width}
            >
              {editComp && editComp(id, isLoaded)}
            </AppDrawer>
          ),
          viewComp: (id: string) => (
            <AppDrawer
              title={`View ${moduleName}`}
              toggler={(showDrawer) => (
                <Tooltip title={`View ${moduleName}`}>
                  <EyeOutlined
                    onClick={() => {
                      showDrawer();
                    }}
                    className="text-sm md:text-lg"
                  />
                </Tooltip>
              )}
              width={width}
            >
              {viewComp && viewComp(id, isLoaded)}
            </AppDrawer>
          ),
          userProfile: (id: string) => (
            <AppDrawer
              title="Profile"
              toggler={(showDrawer) => (
                <EyeOutlined
                  onClick={() => {
                    showDrawer();
                  }}
                  className="text-sm md:text-lg"
                />
              )}
              width={width}
            >
              {userProfile && userProfile(id, isLoaded)}
            </AppDrawer>
          ),
          proceedComp: (id: string, isDisable: number) => (
            <AppDrawer
              title={`Proceed ${moduleName}`}
              toggler={(showDrawer) => (
                <Tooltip
                  title={
                    isDisable === 1
                      ? "Survey is already proceeded"
                      : "Proceed Survey"
                  }
                >
                  <AuditOutlined
                    onClick={() => {
                      isDisable !== 1 && showDrawer();
                    }}
                    className={`text-sm md:text-lg ${
                      isDisable === 1 && "text-gray-400 cursor-not-allowed"
                    }`}
                  />
                </Tooltip>
              )}
              width={width}
            >
              {editComp && editComp(id, isLoaded)}
            </AppDrawer>
          ),
        });
      }
      return child;
    });
  };
  const customIcon = (
    <LoadingOutlined
      style={{ fontSize: 40, color: "rgb(45, 117, 109)" }}
      spin
    />
  );

  const actionOptions = [
    {
      id: "active",
      name: "Notice",
    },
    {
      id: "inactive",
      name: "In-Active",
    },
  ];
  const surveyOptions = [
    {
      id: "All",
      name: "All",
    },
    {
      id: "Notice",
      name: "Notice",
    },
    {
      id: "NoticeReply",
      name: "Notice Reply",
    },
    {
      id: "ShowCause",
      name: "Show Cause Notice",
    },
    {
      id: "ShowCauseReply",
      name: "Show Cause Notice Reply",
    },
    {
      id: "Challan/Fee",
      name: "Challan/Fee",
    },
    {
      id: "Closed",
      name: "Close",
    },
    {
      id: "Seal",
      name: "Seal",
    },
    {
      id: "DescalingFee",
      name: "Descaling Fee",
    },
    {
      id: "De-seal",
      name: "De-seal",
    },
    {
      id: "Demolition",
      name: "Demolation",
    },
    {
      id: "DisposeOff",
      name: "Dispose Off",
    },
  ];

  return (
    <div className="p-0 sm:p-4 md:p-6 lg:p-8">
      <h2
        className={`text-xl md:text-2xl lg:text-3xl font-bold px-4 mb-4 md:mb-6 ${styles.title}`}
      >
        {pagetTitle}
      </h2>

      <div
        className={`flex justify-between gap-5 my-4 px-4 items-center flex-col md:flex-row ${styles.title}`}
      >
        <div className="flex gap-5">
          {(pagetTitle === "User Management" ||
            pagetTitle === "Area Management" ||
            pagetTitle === "Sub-Area Management" ||
            pagetTitle === "Survey Management" ||
            pagetTitle === "Society Survey Management" ||
            pagetTitle === "Society Management") && (
            <>
              <div className="flex items-center">
                <Input
                  type="text"
                  placeholder="Search..."
                  className="border rounded-lg px-4 py-1 w-full md:w-72"
                  style={{ borderColor: "rgba(0, 0, 0, 20%)" }}
                  suffix={<SearchOutlined />}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </>
          )}
          {pagetTitle === "User Management" && (
            <>
              <div className="flex items-center">
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Status"
                  className="rounded-md w-full md:w-72"
                  onChange={(e) => {
                    setStatusValue(e);
                  }}
                >
                  {actionOptions?.map((value) => (
                    <Option key={value.id} value={value.id}>
                      {value.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </>
          )}
          {pagetTitle === "Survey Management" && (
            <>
              <div className="flex items-center">
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Status"
                  className="rounded-md w-full md:w-72"
                  onChange={(e) => {
                    setNoticeType(e);
                  }}
                >
                  {surveyOptions?.map((value) => (
                    <Option key={value.id} value={value.id}>
                      {value.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </>
          )}
        </div>

        <div className="flex items-center justify-center">
          {addComp && (
            <AppDrawer
              title={`Add ${moduleName}`}
              toggler={(showDrawer) => (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  style={{
                    backgroundColor: "#2D796D",
                    borderRadius: "15px",
                    borderColor: "#2D796D",
                  }}
                  className="text-white py-2 px-4 md:py-1 md:px-4 text-xs md:text-sm"
                  onClick={showDrawer}
                >
                  {`Add ${moduleName}`}
                </Button>
              )}
              width={width}
            >
              {addComp(isLoaded)}
            </AppDrawer>
          )}
        </div>
      </div>
      {loading ? (
        <Spin
          size="large"
          className="flex justify-center bg-white items-center h-[200px]"
          indicator={customIcon}
          spinning={loading}
          delay={500}
        />
      ) : (
        listComp && renderChildrenWithProps()
      )}
    </div>
  );
};

export default AppPageWrapper;
