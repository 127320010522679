export const PAGE_LIMIT = 8;
export const DEFAULT_PAGE = 1;

export const CLIENT_BASE_URL =
  process.env.CLIENT_BASE_URL || "https://dev-cdasurveyportal.ermispk.com";

export const API_BASE_URL =
  process.env.API_BASE_URL || "https://dev-apicdasurveyportal.ermispk.com/";

export const SITE_KEY =
  process.env.SITE_KEY || "6LcHuigqAAAAAKYwPDi5X8ddwBBVCRK0AMalCJJe";

export const IMG_BASE_URL = `${API_BASE_URL}uploads/`;
export const GOOGLE_MAP_API_KEY = "AIzaSyBLEnoiCoDDt9QElmjzuMLeHbXbClssEG8";
