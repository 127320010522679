import React, { useEffect, useState } from "react";
import { Form, Input, notification, Select } from "antd";
import {
  useEditAreaMutation,
  useGetAreaByIdQuery,
  useGetPermissionsQuery,
  useGetSubZoneDropdownQuery,
  useGetZoneDropdownQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { areaValidator } from "@validators/areaValidator";
import NotificationHandler from "@components/notificationHandler";

const { Option } = Select;

interface EditAreaProps {
  onClose?: () => void;
  id: string;
}

const EditArea: React.FC<EditAreaProps> = ({ onClose, id }) => {
  const { openNotificationWithIcon } = NotificationHandler();
  const [editArea, { isLoading, error }] = useEditAreaMutation();
  const [selectedZoneId, setSelectedZoneId] = useState<string | null>("");

  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  const {
    data: zone,
    error: zoneError,
    isLoading: zoneIsLoading,
    // refetch: refetchZone,
  } = useGetZoneDropdownQuery();

  const {
    data: subZoneList,
    error: subZoneError,
    isLoading: subZoneIsLoading,
    refetch: refetchSubZone,
  } = useGetSubZoneDropdownQuery({ zoneId: selectedZoneId as string });
  const {
    data: areaById,
    error: areaByIdError,
    isLoading: areaByIdIsLoading,
  } = useGetAreaByIdQuery(id);

  const onFinish = async (values: any) => {
    try {
      const payload = {
        name: values.name,
        subZoneId: values.subZoneId,
        id,
      };
      const result = await editArea({ id, body: payload }).unwrap();
      onClose && onClose();
      openNotificationWithIcon(
        "success",
        result?.message || "Area updated successfully!"
      );
    } catch (err: any) {
      console.error("Area update failed:", err);

      // openNotificationWithIcon("error", err?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (areaById) {
      setSelectedZoneId(areaById?.data?.zoneId ?? null);
      refetchSubZone();
      form.setFieldsValue({
        name: areaById?.data.name,
        zoneId: areaById?.data.zoneId,
        subZoneId: areaById?.data.subZoneId,
      });
    }

    return () => form.resetFields();
  }, [areaById, form, permissions]);

  const handleZoneChange = (newZoneId: string | null) => {
    setSelectedZoneId(newZoneId);
    if (newZoneId) {
      refetchSubZone();
    }
    form.resetFields(["subZoneId"]);
  };

  return (
    <AppFormWrapper
      form={form}
      error={error || permissionsError || areaByIdError}
      isLoading={isLoading || permissionsIsLoading || areaByIdIsLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Edit"
    >
      <Form.Item label="Name" name="name" rules={areaValidator.name}>
        <Input
          maxLength={30}
          className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item label="Zone" name="zoneId" rules={areaValidator.subZone}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select zone "
          loading={zoneIsLoading}
          disabled={zoneIsLoading || isLoading}
          className="rounded-md"
          onChange={handleZoneChange}
        >
          {zone?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Sub Zone"
        name="subZoneId"
        rules={areaValidator.subZone}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select sub zone "
          loading={subZoneIsLoading}
          disabled={subZoneIsLoading || isLoading}
          className="rounded-md"
        >
          {subZoneList?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default EditArea;
