import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { ResponseList, Supervisor, SupervisorPayload } from "types";
import apiRoutes from "../apiRoutes";

export const supervisiorApi = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    "api"
  >
) => ({
  getSupervisor: builder.query<
    ResponseList<Supervisor>,SupervisorPayload>({
      query: ({ hierarchy, department }) => ({url: apiRoutes.getSupervisor(hierarchy ? `?hierarchy=${hierarchy}?department=${department}` : ""),
      method: "GET",
    }),
  }),
});
