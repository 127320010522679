import React, { useState } from "react";
import { Form, Input, Button, Select, Upload, Image, message } from "antd";
import {
  useGetDesignationsQuery,
  useGetDepartmentQuery,
  useGetDivisionQuery,
  useGetZoneDropdownQuery,
  useGetSupervisorQuery,
  useCreateUserMutation,
  useGetRoleDropdownQuery,
  useGetAreaDropdownQuery,
  useGetUserDropdownQuery,
  useGetSubAreaDropdownQuery,
  useGetSubZoneDropdownQuery,
} from "@services/api";
import { UploadOutlined } from "@ant-design/icons";
import AppFormWrapper from "@components/appFormWrapper";
import { userValidator } from "@validators/userValidator";
import NotificationHandler from "@components/notificationHandler";

const { Option } = Select;
interface AddUserProps {
  onClose?: () => void;
}

const AddUser: React.FC<AddUserProps> = ({ onClose }) => {
  const [form] = Form.useForm();
  const { openNotificationWithIcon } = NotificationHandler();
  const [createUser, { isLoading, error }] = useCreateUserMutation();
  const [pictureBase64, setPictureBase64] = useState<string | null>(null);

  const [filePath, setFilePath] = useState<string>("");

  const [hierarchy, setHierarchy] = useState<string | null>("");
  const [departmentId, setDepartmentId] = useState<string | null>("");

  const [selectedZoneId, setSelectedZoneId] = useState<string | null>("");
  const [selectedSubZoneId, setSelectedSubZoneId] = useState<string | null>("");
  const [selectedAreaId, setSelectedAreaId] = useState<string | null>("");
  const {
    data: designations,
    error: designationsError,
    isLoading: designationsIsLoading,
  } = useGetDesignationsQuery();
  const {
    data: department,
    error: departmentError,
    isLoading: departmentIsLoading,
  } = useGetDepartmentQuery();
  const {
    data: division,
    error: divisionError,
    isLoading: divisionIsLoading,
  } = useGetDivisionQuery();
  const {
    data: zone,
    error: zoneError,
    isLoading: zoneIsLoading,
    refetch: refetchZone,
  } = useGetZoneDropdownQuery();

  const {
    data: subZone,
    error: subZoneError,
    isLoading: subZoneIsLoading,
    refetch: refetchSubZone,
  } = useGetSubZoneDropdownQuery({ zoneId: selectedZoneId as string });
  const {
    data: supervisor,
    error: supervisorError,
    isLoading: supervisorIsLoading,
  } = useGetSupervisorQuery({
    hierarchy: hierarchy as string,
    department: departmentId as string,
  });
  const {
    data: role,
    error: roleError,
    isLoading: roleIsLoading,
  } = useGetRoleDropdownQuery({ hierarchy: hierarchy as string });

  const {
    data: area,
    isLoading: areaIsLoading,
    refetch: refetchAreas,
    isUninitialized,
  } = useGetAreaDropdownQuery({ subZoneId: selectedSubZoneId as string });

  const {
    data: subAreaData,
    error: subAreasError,
    isLoading: subAreasIsLoading,
    refetch: refetchSubAreas,
  } = useGetSubAreaDropdownQuery({ areaId: selectedAreaId as string });
  //temporary dropdown Data
  const {
    data: userDropdown,
    error: userDropdownError,
    isLoading: userDropdownIsLoading,
  } = useGetUserDropdownQuery();

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleUploadChange = async ({ fileList }: any) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const base64 = await getBase64(file);
      setPictureBase64(base64);
    } else {
      setPictureBase64(null);
    }
  };
  const initialValues = {
    name: "",
    email: "",
    CNIC: "",
    phone: "",
    picture: undefined,
    designationId: undefined,
    departmentId: undefined,
    divisionId: undefined,
    zoneId: undefined,
    subZoneId: undefined,
    areasIds: undefined,
    subAreasIds: undefined,
    supervisorId: undefined,
    roleId: undefined,
  };
  const onFinish = async (values: any) => {
    console.log("picturevalue", values.picture);
    try {
      delete values.confirmPassword;

      const payload = {
        name: values.name || undefined,
        email: values.email || undefined,
        CNIC: values.CNIC || undefined,
        phone: values.phone || undefined,
        picture: pictureBase64 || undefined,
        designationId: values.designationId || undefined,
        departmentId: values.departmentId || undefined,
        zoneId: values.zoneId || undefined,
        subZoneId: values.subZoneId || undefined,
        divisionId: values.divisionId || undefined,
        roleId: values.roleId || undefined,
        supervisorId: values?.supervisorId || undefined,
        areasIds: values.areasIds || undefined,
        subAreasIds: values.subAreasIds || undefined,
      };
      const result = await createUser(payload).unwrap();
      console.log("User Created successful:", result);
      openNotificationWithIcon(
        "success",
        result?.message || "User Created Successfully!"
      );
      form.resetFields();
      onClose && onClose();
    } catch (err: any) {
      console.error("Update failed:", err);

      // openNotificationWithIcon("error", err?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleZoneChange = (newZoneId: string | null) => {
    setSelectedZoneId(newZoneId);
    if (newZoneId && !isUninitialized) {
      refetchSubZone();
    }
    form.resetFields(["subZoneId", "areasIds", "subAreasIds"]);
  };
  const handleSubZoneChange = (newSubZoneId: string | null) => {
    setSelectedSubZoneId(newSubZoneId);
    if (newSubZoneId && !isUninitialized) {
      refetchAreas();
    }
    form.resetFields(["areasIds", "subAreasIds"]);
  };

  const handleAreaChange = (newAreaIds: string | null) => {
    setSelectedAreaId(newAreaIds);

    if (!isUninitialized) {
      refetchSubAreas();
    }

    const currentSubAreasIds = form.getFieldValue("subAreasIds") || [];
    const validSubAreasIds =
      subAreaData?.data
        .filter((subArea: any) => newAreaIds?.includes(subArea?.areaId))
        .map((subArea: any) => subArea?.id) || [];
    const updatedSubAreasIds = currentSubAreasIds.filter((subAreaId: string) =>
      validSubAreasIds.includes(subAreaId)
    );
    form.setFieldsValue({
      subAreasIds: updatedSubAreasIds,
    });
  };

  const handleDesignationChange = (value: number) => {
    form.setFieldsValue({
      roleId: undefined,
      supervisorId: undefined,
      departmentId: undefined,
    });
    const filteredDesignation =
      designations &&
      designations?.data?.find((item) => {
        return item?.id === value;
      });
    if (filteredDesignation) {
      setHierarchy(filteredDesignation?.hierarchy?.toString());
    }
  };
  const handleDirectorateChange = (value: number) => {
    form.setFieldsValue({
      roleId: undefined,
      supervisorId: undefined,
    });
    const filteredDirectorate =
      department &&
      department?.data?.find((item) => {
        return item?.id === value;
      });
    console.log("filteredDirectorate", filteredDirectorate);
    if (filteredDirectorate) {
      setDepartmentId(filteredDirectorate?.id?.toString());
    }
  };

  const handleImageChange = (info: any) => {
    if (info.file.status === "done" || info.file.status === "uploading") {
      // Get the file name (You can't access the absolute file path due to browser restrictions)
      const fileName = info.file.name;
      setFilePath(fileName);
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const validateFileType = (file: File) => {
    const isValidType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpg";

    if (!isValidType) {
      message.error("You can only upload image files (PNG, JPEG, JPG, GIF)!");
    }
    return isValidType || Upload.LIST_IGNORE; // Will ignore the file if not valid
  };

  return (
    <AppFormWrapper
      form={form}
      error={error}
      initialValues={initialValues}
      isLoading={isLoading}
      onClose={onClose}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      type="Add"
    >
      <Form.Item label="Name" name="name" rules={userValidator.name}>
        <Input
          maxLength={40}
          placeholder="Enter Your Name"
          className="rounded-md p-1"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item label="Email" name="email" rules={userValidator.email}>
        <Input
          maxLength={40}
          placeholder="Enter Your Email"
          className="rounded-md p-1"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item label="CNIC" name="CNIC" rules={userValidator.cnic}>
        <Input
          maxLength={13}
          placeholder="Enter Your CNIC in digits"
          className="rounded-md p-1"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item label="Phone Number" name="phone" rules={userValidator.phone}>
        <Input
          maxLength={11}
          placeholder="Enter Your Phone number in digits"
          className="rounded-md p-1"
          disabled={isLoading}
        />
      </Form.Item>

      <Form.Item
        className="flex flex-col"
        label="Picture"
        name="picture"
        rules={userValidator.picture}
      >
        <div className="flex flex-col gap-1">
          {pictureBase64 && (
            <Image width={60} src={pictureBase64} alt="User Picture" />
          )}
          <Upload
            onChange={handleUploadChange}
            beforeUpload={validateFileType}
            maxCount={1}
            accept="image/png, image/gif, image/jpeg, image/jpg"
          >
            <Button
              disabled={isLoading}
              icon={<UploadOutlined />}
              className="custom-upload-button p-1"
            >
              Click to Upload Image
            </Button>
          </Upload>
        </div>
      </Form.Item>

      <Form.Item
        label="Designation"
        name="designationId"
        rules={userValidator.designationId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select designation"
          loading={designationsIsLoading}
          disabled={designationsIsLoading || isLoading}
          className="rounded-md"
          onChange={handleDesignationChange}
        >
          {designations?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Directorate"
        name="departmentId"
        rules={userValidator.departmentId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select directorate"
          loading={departmentIsLoading}
          disabled={departmentIsLoading || isLoading}
          className="rounded-md"
          onChange={handleDirectorateChange}
        >
          {department?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Role" name="roleId" rules={userValidator.roleId}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select role"
          loading={roleIsLoading}
          disabled={!hierarchy || roleIsLoading || isLoading}
          className="rounded-md"
        >
          {role?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Supervisor"
        name="supervisorId"
        rules={userValidator.supervisorId}
      >
        <Select
          placeholder="Select supervisor"
          loading={supervisorIsLoading}
          disabled={!hierarchy || supervisorIsLoading || isLoading}
          className="rounded-md"
        >
          {supervisor?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Zone" name="zoneId" rules={userValidator.zoneId}>
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select zone"
          loading={zoneIsLoading}
          disabled={zoneIsLoading || isLoading}
          className="rounded-md"
          onChange={handleZoneChange}
        >
          {zone?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Sub Zone"
        name="subZoneId"
        rules={userValidator.subZoneId}
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Select sub zone"
          loading={zoneIsLoading}
          disabled={!selectedZoneId || zoneIsLoading || isLoading}
          className="rounded-md"
          onChange={handleSubZoneChange}
        >
          {subZone?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Area" name="areasIds" rules={userValidator.areasIds}>
        <Select
          showSearch
          optionFilterProp="children"
          mode="multiple"
          allowClear
          placeholder="Select area"
          loading={areaIsLoading}
          disabled={!selectedSubZoneId || areaIsLoading || isLoading}
          onChange={handleAreaChange}
        >
          {area?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Sub Area"
        name="subAreasIds"
        rules={userValidator.subAreasIds}
      >
        <Select
          showSearch
          optionFilterProp="children"
          mode="multiple"
          allowClear
          placeholder="Select sub area"
          loading={areaIsLoading}
          disabled={!selectedAreaId || areaIsLoading || isLoading}
        >
          {subAreaData?.data?.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </AppFormWrapper>
  );
};

export default AddUser;
