import React, { useEffect, useMemo, useState } from "react";
import { Form, Input, Checkbox, Skeleton, Alert } from "antd";
import {
  useGetPermissionsQuery,
  useGetPropertyTypeByIdQuery,
} from "@services/api/";
import AppFormWrapper from "@components/appFormWrapper";
import { Tag } from "antd";

interface ViewPropertyProps {
  onClose?: () => void;
  id: string;
}

const ViewPropertyType: React.FC<ViewPropertyProps> = ({ onClose, id }) => {
  const {
    data: property,
    error: propertyTypeError,
    isLoading: propertyTypeIsLoading,
  } = useGetPropertyTypeByIdQuery(id);

  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetPermissionsQuery();

  const tags = useMemo(() => {
    let propertyUsages = property?.data?.usages;
    console.log("property", propertyUsages);
    if (propertyUsages) return propertyUsages.split(",");
  }, [property]);

  return (
    <div className="max-w-3xl -mx-4 px-4  bg-white rounded-lg overflow-y-auto lg:max-h-[calc(100vh-180px)]">
      {permissionsIsLoading || propertyTypeIsLoading ? (
        <Skeleton active />
      ) : (
        <>
          <AppFormWrapper
            onClose={onClose}
            error={propertyTypeError}
            isLoading={propertyTypeIsLoading}
          >
            <h1>Name</h1>
            <Input
              disabled
              value={property?.data.name}
              className="rounded-md p-1 border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />{" "}
            <h1>Usages</h1>
            {tags && tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)}
          </AppFormWrapper>
        </>
      )}
    </div>
  );
};

export default ViewPropertyType;
