import React, { ReactNode, useState } from "react";
import { Button, Layout, Menu, Modal } from "antd";
import Sider from "antd/es/layout/Sider";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  UserOutlined,
  TeamOutlined,
  BarsOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  FormOutlined,
  FileSearchOutlined,
  PropertySafetyOutlined,
  WarningOutlined,
  AreaChartOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import Navbar from "@components/navbar";
import dashboardPaths from "@paths/dashboardPaths";
import userManagementPaths from "@paths/userManagementPaths";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "types";
import roleManagementPaths from "@paths/roleManagementPaths";
import zoneManagementPaths from "@paths/zoneManagement";
import taskManagementPaths from "@paths/taskManagement";
import usePermissions from "@hooks/usePermissions";
import { permissions } from "permissions";
import styles from "./AppSidebar.module.css";
import classNames from "classnames";
import propertyTypeManagementPaths from "@paths/propertyType";
import violationTypeManagementPaths from "@paths/violationType";
import permissionManagementPaths from "@paths/permissionManagementPaths";
import areaManagementPaths from "@paths/areaManagementPaths";
import challanManagementPaths from "@paths/challanManagementPaths";
import subAreaManagementPaths from "@paths/subAreaManagementPaths";
import image1 from "../../assets/img/logo.svg";
import surveyManagementPaths from "@paths/surveyPaths";
import { useMediaQuery } from "react-responsive";
import subZoneManagementPaths from "@paths/subZonemanagementPaths";
import societySurveyPaths from "@paths/societySurveyPaths";
import societyManagementPaths from "@paths/societyManagementPaths";
import societyViolationManagementPaths from "@paths/societyViolationPath";
import societyUserManagementPaths from "@paths/societyUserManagementPaths";

interface AppSidebarProps {
  children: ReactNode;
  user: User | null;
}

const AppSidebar: React.FC<AppSidebarProps> = ({ children, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { hasPermission } = usePermissions();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const handleMenuClick = (e: any) => {
    navigate(e.key);
    if (isTabletOrMobile) {
      setIsModalVisible(false); // Close the modal after clicking a menu item
    }
  };

  const menuItems = [
    {
      key: dashboardPaths.root,
      icon: <HomeOutlined />, // Dashboard
      label: "Dashboard",
      permission: permissions.VIEW_DASHBOARD,
    },
    {
      key: userManagementPaths.root,
      icon: <UserOutlined />, // User Management
      label: "User Management",
      permission: permissions.VIEW_USER_MANAGEMENT,
    },
    {
      key: roleManagementPaths.root,
      icon: <TeamOutlined />, // Role Management
      label: "Role Management",
      permission: permissions.VIEW_ROLE_MANAGEMENT,
    },
    {
      key: permissionManagementPaths.root,
      icon: <AuditOutlined />, // Permission Management
      label: "Permission Management",
      permission: permissions.VIEW_PERMISSION_MANAGEMENT,
    },
    {
      key: zoneManagementPaths.root,
      icon: <EnvironmentOutlined />, // Zone Management
      label: "Zone Management",
      permission: permissions.VIEW_ZONE_MANAGEMENT,
      children: [
        {
          key: zoneManagementPaths.root,
          label: "Zones",
          icon: <AreaChartOutlined />, // Zones
          permission: permissions.VIEW_AREA_MANAGEMENT,
        },
        {
          key: subZoneManagementPaths.root,
          label: "Sub-Zones",
          icon: <AreaChartOutlined />, // Sub-Zones
          permission: permissions.VIEW_AREA_MANAGEMENT,
        },
      ],
    },
    {
      key: taskManagementPaths.root,
      icon: <FileSearchOutlined />, // Survey Management
      label: "Survey Management",
      permission: permissions.VIEW_TASK_MANAGEMENT,
    },
    {
      key: propertyTypeManagementPaths.root,
      icon: <PropertySafetyOutlined />, // Property Type Management
      label: "Property Type Management",
      permission: permissions.VIEW_PROPERTY_MANAGEMENT,
    },
    {
      key: areaManagementPaths.root,
      icon: <GlobalOutlined />, // Area Management
      label: "Area Management",
      permission: permissions.VIEW_AREA_MANAGEMENT,
      children: [
        {
          key: areaManagementPaths.root,
          label: "Areas",
          icon: <GlobalOutlined />, // Areas
          permission: permissions.VIEW_AREA_MANAGEMENT,
        },
        {
          key: subAreaManagementPaths.root,
          label: "Sub-Area",
          icon: <GlobalOutlined />, // Sub-Area
          permission: permissions.VIEW_AREA_MANAGEMENT,
        },
      ],
    },
    {
      key: violationTypeManagementPaths.root,
      icon: <WarningOutlined />, // Violation Type Management
      label: "Violation Type Management",
      permission: permissions.VIEW_VIOLATION_MANAGEMENT,
    },

    // {
    //   key: challanManagementPaths.root,
    //   icon: <FormOutlined />, // Challan Management
    //   label: "Challan Management",
    //   permission: permissions.VIEW_CHALLAN_MANAGEMENT,
    // },
    // {
    //   key: surveyManagementPaths.root,
    //   icon: <FileSearchOutlined />, // Survey Management (Duplicate but represents the same function)
    //   label: "Survey Management",
    //   permission: permissions.VIEW_TASK_MANAGEMENT,
    // },
    {
      key: societyManagementPaths.root,
      icon: <FileSearchOutlined />, // Society Management (Duplicate but represents the same function)
      label: "Society Management",
      permission: permissions.VIEW_SOCIETY_MANAGEMENT,
    },
    {
      key: societySurveyPaths.root,
      icon: <FileSearchOutlined />, // Society Survey (Duplicate but represents the same function)
      label: "Society Survey Management",
      permission: permissions.VIEW_SOCIETY_SURVEY_MANAGEMENT,
    },

    {
      key: societyViolationManagementPaths.root,
      icon: <WarningOutlined />, // Violation Type Management
      label: "Society Violation Management",
      permission: permissions.VIEW_SOCIETY_VIOLATION_MANAGEMENT,
    },
    {
      key: societyUserManagementPaths.root,
      icon: <FileSearchOutlined />, // Society User Management (Duplicate but represents the same function)
      label: "Society User Managements",
      permission: permissions.VIEW_SOCIETY_USER_MANAGEMENT,
    },
  ].filter((item) => hasPermission(item.permission));

  const getMenuKey = () => {
    const path = location.pathname;
    if (path.startsWith(dashboardPaths.root)) {
      return dashboardPaths.root;
    } else if (path.startsWith(userManagementPaths.root)) {
      return userManagementPaths.root;
    } else if (path.startsWith(roleManagementPaths.root)) {
      return roleManagementPaths.root;
    } else if (path.startsWith(zoneManagementPaths.root)) {
      return zoneManagementPaths.root;
    } else if (path.startsWith(taskManagementPaths.root)) {
      return taskManagementPaths.root;
    } else if (path.startsWith(propertyTypeManagementPaths.root)) {
      return propertyTypeManagementPaths.root;
    } else if (path.startsWith(violationTypeManagementPaths.root)) {
      return violationTypeManagementPaths.root;
    } else if (path.startsWith(areaManagementPaths.root)) {
      return areaManagementPaths.root;
    } else if (path.startsWith(areaManagementPaths.area)) {
      return areaManagementPaths.area;
    } else if (path.startsWith(subAreaManagementPaths.root)) {
      return subAreaManagementPaths.root;
    } 
    // else if (path.startsWith(challanManagementPaths.root)) {
    //   return challanManagementPaths.root;
    // } 
    else if (path.startsWith(surveyManagementPaths.root)) {
      return surveyManagementPaths.root;
    } else if (path.startsWith(societyViolationManagementPaths.root)) {
      return societyViolationManagementPaths.root;
    } else if (path.startsWith(societySurveyPaths.root)) {
      return societySurveyPaths.root;
    } else if (path.startsWith(societyManagementPaths.root)) {
      return societyManagementPaths.root;
    } else if (path.startsWith(societyUserManagementPaths.root)) {
      return societyUserManagementPaths.root;
    }
    return path;
  };

  return (
    <Layout hasSider>
      {isTabletOrMobile ? (
        <>
          <Modal
            title="Menu"
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            width="100%"
          >
            <Menu
              theme="light"
              className={styles.menu}
              mode="inline" // Changed from "inline" to "vertical"
              selectedKeys={[getMenuKey()]}
              onClick={handleMenuClick}
            >
              {menuItems.map((item) =>
                item.children ? (
                  <Menu.SubMenu
                    key={item.key}
                    icon={item.icon}
                    title={item.label}
                    className={classNames(styles.menuItem, {
                      [styles.activeMenuItem]: getMenuKey() === item.key,
                    })}
                  >
                    {item.children.map((subItem) => (
                      <Menu.Item
                        key={subItem.key}
                        icon={subItem.icon}
                        className={classNames(styles.menuItem, {
                          [styles.activeMenuItem]: getMenuKey() === subItem.key,
                        })}
                        title={subItem.label}
                      >
                        {subItem.label}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    className={classNames(styles.menuItem, {
                      [styles.activeMenuItem]: getMenuKey() === item.key,
                    })}
                    title={item.label}
                  >
                    {item.label}
                  </Menu.Item>
                )
              )}
            </Menu>
          </Modal>
        </>
      ) : (
        <Sider
          style={{
            backgroundColor: "#F8F9FA",
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: 15,
          }}
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={classNames(styles.sidebar, {
            [styles.sidebarCollapsed]: collapsed,
            [styles.sidebarExpanded]: !collapsed,
          })}
        >
          <div className="cursor-pointer pt-1 pl-7">
            <img
              src={image1}
              alt="Description"
              className="w-[100px] pt-6 pb-3"
            />
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={toggle}
              style={{ color: "#2D796D" }}
              className="lg:hidden"
            />
          </div>
          <Menu
            theme="light"
            className={styles.menu}
            mode="inline"
            selectedKeys={[getMenuKey()]}
            onClick={handleMenuClick}
          >
            {menuItems.map((item) =>
              item.children ? (
                <Menu.SubMenu
                  key={item.key}
                  icon={item.icon}
                  title={item.label}
                  className={classNames(styles.menuItem, {
                    [styles.activeMenuItem]: getMenuKey() === item.key,
                  })}
                >
                  {item.children.map((subItem) => (
                    <Menu.Item
                      key={subItem.key}
                      icon={subItem.icon}
                      className={classNames(styles.menuItem, {
                        [styles.activeMenuItem]: getMenuKey() === subItem.key,
                      })}
                      title={subItem.label}
                    >
                      {subItem.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  className={classNames(styles.menuItem, {
                    [styles.activeMenuItem]: getMenuKey() === item.key,
                  })}
                  title={item.label}
                >
                  {item.label}
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
      )}
      <Layout
        className={`${
          isTabletOrMobile ? "" : "ml-[260px]"
        } bg-[#F8F9FA] transition-all duration-300`}
      >
        <Navbar user={user} setIsModalVisible={setIsModalVisible} />
        <Content
          style={{
            overflow: "initial",
            margin: "20px",
            marginTop: "15px",
            backgroundColor: "white",
            borderRadius: "20px 20px",
            padding: "20px 5px",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppSidebar;
